import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { AuthenticationServiceService } from "src/services/authentication-service.service";
import { RailFleetService } from "src/services/rail-fleet.service";

@Injectable({
    providedIn:'root'
})
export class AuthGuard implements CanActivate {
    /**
     *
     */
    constructor(private router: Router, private _service: RailFleetService, private auth: AuthenticationServiceService) {


    }

    canActivate(route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        this._service.getUserInfo(sessionStorage.getItem('userId')).subscribe((response: any[]) => {
            //console.log(response, "AuthGaurdResp")
            if (response.length > 0) {
                return true;
            } else {
                this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
                return false;
            }
        })
        return true;
    }
}