import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SelectItem } from 'primeng/api';
import { RailCarLinks } from 'src/app/_models/RailCarLinks';
import { RailCarURLs } from 'src/app/_models/railcarurls';
import { RailFleetService } from 'src/services/rail-fleet.service';

@Component({
  selector: 'app-railcar-links',
  templateUrl: './railcar-links.component.html',
  styleUrls: ['./railcar-links.component.css']
})
export class RailcarLinksComponent implements OnInit {

   railcarURLs: any;

    cols: any[];
    carUrls: SelectItem[];
    apiResp:any;
    routerLinkVariable:any;
    railLinks:RailCarLinks[]=[];

    constructor(private _Service: RailFleetService,private router: Router) {
      
     }

    ngOnInit() {
        this._Service.getAllRailCarUrls().subscribe(response => {
          this.railcarURLs=response;
          this.railLinks=this.railcarURLs;
        });

        this.cols = [
            { field: 'descriptionName', header: 'Description Name' },
            { field: 'url', header: 'URLS' }           
        ];
        this.routerLinkVariable
       
    }
    onGoToPage2(data:any)
    {
      window.open(data, '_blank');
    }
    backToGrid()
    {
      this.router.navigate(['/rail-car']);
    }

}
