import { Component, OnInit, SimpleChanges, ViewEncapsulation, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { FilterUtils, LazyLoadEvent } from 'primeng/api';
import { RailFleetService } from '../../../services/rail-fleet.service';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { ToastrService } from 'ngx-toastr';
import { element } from 'protractor';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { EMLINK } from 'constants';
import { SelectItem } from 'primeng/api';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

const EXCEL_EXTENSION = '.xlsx';

@Component({
  selector: 'app-invoice-edit',
  templateUrl: './invoice-edit.component.html',
  styleUrls: ['./invoice-edit.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class InvoiceEditComponent implements OnInit,OnChanges {
  @Input() selectedValues: any;
  @Input() selectedCols: any[];
  @Input() tabType :any;
  @Output() bulkEditStatus = new EventEmitter<boolean>();
  activeCarsData: any;
  cols: any[];
  selected=[];
  apiResp: any;
  defaultWidth: '100%';
  viewColHeight: any;
  userId: any;
  userAdmin: boolean = false;
  filteredData: any;
  filteredFields = [];
  tempFilteredData: any;
  masterAPIResp: any;
  permittedCommodities: any;
  // SelectStatus: any;
  SelectStatus: SelectItem[];
  hazardClasses: any;
  carTypes: any;
  dot_aar_classes: any;
  carBuilders: any;

  constructor(private _railFleetService: RailFleetService, private router: Router,private spinner: NgxSpinnerService, private _toastr: ToastrService) { }
 
  ngOnChanges(): void {
    this.totalRecords = this.selectedValues.length
    this.loadDataOnScroll({ first: 0, rows: 20 })
  }

  ngOnInit() {
    this.userId = sessionStorage.getItem('userId');
    let role = sessionStorage.getItem('role');
    if(role != null && role != undefined && role != '' ) {
      if(role.includes('Admin')) {
        this.userAdmin = true;
      } else {
        this.userAdmin = false;
      }
    }  else {
      this.userAdmin = false;
    }
    // this.getAllMasterData()
    if (this.userId != null && this.userId != 'undefined' && this.userId != '') {
      // this.spinner.show();
      
        this.cols = [
          // { "header_id": 1, "field": "status", "header": "Response", "options": [], "width": '100px', "isShow": true, "isEditable": true, "selectedOptions": [], "serialNo": 1 },
          { "header_id": 2, "field": "railcarID", "header": "Railcar ID", "options": [], "width": '100px', "isShow": true, "isEditable": false, "selectedOptions": [], "serialNo": 2 },
          { "header_id": 3, "field": "carstatus", "header": "Car status", "options": [], "width": '100px', "isShow": true, "isEditable": false, "selectedOptions": [], "serialNo": 3 },
          { "header_id": 4, "field": "owner", "header": "Owner", "options": [], "width": '200px', "isShow": true, "isEditable": false, "selectedOptions": [], "serialNo": 4 },
          { "header_id": 5, "field": "tcC_Cost_Center", "header": "TCC (Cost Center)", "options": [], "width": '100px', "isShow": true, "isEditable": true, "selectedOptions": [], "serialNo": 5 },
          { "header_id": 6, "field": "gL_Account_Number", "header": "GL Account Number", "options": [], "width": '100px', "isShow": true, "isEditable": true, "selectedOptions": [], "serialNo": 6 },
          { "header_id": 7, "field": "gL_Account_Name", "header": "GL Account Name", "options": [], "width": '350px', "isShow": true, "isEditable": true, "selectedOptions": [], "serialNo": 7 },
          { "header_id": 8, "field": "mapping_Category", "header": "Mapping Category", "options": [], "width": '200px', "isShow": true, "isEditable": true, "selectedOptions": [], "serialNo": 8 },
          { "header_id": 9, "field": "component_Group", "header": "Component Group", "options": [], "width": '200px', "isShow": true, "isEditable": true, "selectedOptions": [], "serialNo": 9 },
          { "header_id": 10, "field": "narrative", "header": "Narrative", "options": [], "width": '100px', "isShow": true, "isEditable": false, "selectedOptions": [], "serialNo": 10 },
          { "header_id": 11, "field": "total", "header": "Total", "options": [], "width": '100px', "isShow": true, "isEditable": false, "selectedOptions": [], "serialNo": 11 },
          { "header_id": 12, "field": "labor_Cost", "header": "Labor Cost", "options": [], "width": '100px', "isShow": true, "isEditable": false, "selectedOptions": [], "serialNo": 12 },
          { "header_id": 13, "field": "material_Cost", "header": "Material Cost", "options": [], "width": '100px', "isShow": true, "isEditable": false, "selectedOptions": [], "serialNo": 13 },
          { "header_id": 14, "field": "invoicing_Company", "header": "Invoicing Company", "options": [], "width": '200px', "isShow": true, "isEditable": false, "selectedOptions": [], "serialNo": 14 },
          { "header_id": 15, "field": "bu", "header": "BU", "options": [], "width": '100px', "isShow": true, "isEditable": false, "selectedOptions": [], "serialNo": 15 },
          { "header_id": 16, "field": "car_Type", "header": "Car Type", "options": [], "width": '100px', "isShow": true, "isEditable": false, "selectedOptions": [], "serialNo": 16 },
          { "header_id": 17, "field": "age_of_Car", "header": "Age of Car", "options": [], "width": '100px', "isShow": true, "isEditable": false, "selectedOptions": [], "serialNo": 17 },
          { "header_id": 18, "field": "fleet", "header": "Fleet", "options": [], "width": '200px', "isShow": true, "isEditable": false, "selectedOptions": [], "serialNo": 18 },
          { "header_id": 19, "field": "allT_Invoice_Ref", "header": "ALLT Invoice Ref", "options": [], "width": '100px', "isShow": true, "isEditable": false, "selectedOptions": [], "serialNo": 19 },
          { "header_id": 20, "field": "cutoff_Date", "header": "Cutoff Date", "options": [], "width": '150px', "isShow": true, "isEditable": false, "selectedOptions": [], "serialNo": 20 }
        ];
        // console.log('edit');
        // console.log(this.tabType);
        // if(this.tabType !=2){
        // // this.selectedCols.push(this.cols[0]);
        // }
      this.cols.forEach(element => {
        this.selectedCols.forEach(elm => {
          if (element.field === elm.field) {

            if(elm.selectedOptions.length!=0)
            {
              element.selectedOptions=elm.selectedOptions;
            this.selected.push(element);
            }else{
              this.selected.push(element);
            }
          } 
          
        });
      });
      this.selectedCols=this.selected;
      this.viewColHeight = (window.innerHeight - 190) + "px";
      FilterUtils['custom'] = (value, filter): boolean => {
        if (filter === undefined || filter === null || filter.trim() === '') {
          return true;
        }

        if (value === undefined || value === null) {
          return false;
        }

        return parseInt(filter) > value;
      }
    } else {
      this.router.navigate(['/login']);
    }
    this.SelectStatus = [{label: 'Accept', value: 'ACCEPT'},{label: 'Reject', value: 'REJECT'}]
     
  }

  onChangeDDValue(value) {
    this.sortColumns(value);
  }

  showHideData(e) {
    this.cols = e;
  }

  sortColumns(value) {
    this.selectedCols = this.selectedCols.sort((a, b) => {
      return a.serialNo - b.serialNo;
    })
  }


  onnSelectRow(rowData, type) {
    this.router.navigate(['rail-car/info'], { state: { data: {cols: this.cols, rowData: rowData, selectionType: type} } });
  }

  filterData(value, filter) {
    if (value == '') {
      if (this.filteredFields.length) {
        this.filteredFields = this.filteredFields.filter(ff => ff.fieldName !== filter);
      } else {
        this.filteredFields = [];
      }
    } else {
      let foundField = this.filteredFields.filter(ff => ff.fieldName == filter);
      if (foundField.length) {
        this.filteredFields.forEach(ff => {
          if (ff.fieldName == filter) {
            ff.value = value;
          }
        })
      } else {
        let fieldObj = {
          fieldName: filter,
          value: value
        };
        this.filteredFields.push(fieldObj);
      }
    }
    if (this.filteredFields.length) {
      let temp = this.activeCarsData;
      this.filteredFields.forEach(ff => {
        temp = temp.filter(fd => fd[ff.fieldName].toUpperCase().includes(ff.value.toUpperCase()));
      })
      this.filteredData = temp;
    } else {
      this.filteredData = JSON.parse(JSON.stringify(this.activeCarsData));
    }
    this.tempFilteredData = JSON.parse(JSON.stringify(this.filteredData));
  }

  exportExcel() {
    let tFilteredData = JSON.parse(JSON.stringify(this.filteredData));
    tFilteredData.forEach(ac => {
      let pcValue = "";
      if(ac.permittedCommodity.length > 0) {
        ac.permittedCommodity.forEach(pc => {
          pcValue = pcValue + pc.permittedcommodities + ", "
        });
        ac.permittedCommodity = pcValue;
      }
    }) 
    this.exportAsExcelFile(tFilteredData, 'RailCarFleet', 'sheet1', this.cols)
  }

  

  exportAsExcelFile(json: any[], excelFileName: string, sheetName: string, columnValues: any[]): void {
    var fieldValues: any[] = columnValues.map(field => field.field);
    var headerValues: string[] = columnValues.map(header => header.header);
    var displayedJson: any[] = [];
    if (json != null && json.length > 0) {
      for (let originalValue of json) {
        var jsonObject: any = {};
        for (let fieldValue of fieldValues) {
          jsonObject[fieldValue] = originalValue[fieldValue];
        }
        var jsonString = JSON.stringify(jsonObject);
        displayedJson.push(JSON.parse(jsonString));
      }
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(displayedJson);
      var range = XLSX.utils.decode_range(worksheet['!ref']);
      for (var C = range.s.c; C <= range.e.c; ++C) {
        var address = XLSX.utils.encode_col(C) + "1"; // <-- first row, column number C
        if (!worksheet[address]) continue;
        worksheet[address] = {
          v: headerValues[C],
          t: 's',
          s: {
            alignment: { textRotation: 90 },
            font: { bold: true }
          }
        };
      }
      XLSX.utils.sheet_add_aoa(worksheet, [[]], { origin: -1 });
      const workbook: XLSX.WorkBook = { Sheets: { [sheetName]: worksheet }, SheetNames: [sheetName] };
      const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, excelFileName);
    }

  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
  }
  editBulk() {
    const selectedCheckbox = this.activeCarsData.filter(val => val[0]);
  }
  getAllMasterData() {
    this.spinner.show();
    // this._railFleetService.getRailMasterData().subscribe(response => {
    //   this.masterAPIResp = response;
    //   this.permittedCommodities = this.masterAPIResp.permittedCommodities;
    //   this.permittedCommodities.forEach(pc => {
    //     // pc.railcarID = this.rowData.railcarID;
    //     pc.permittedCommoditiesDesc = null;
    //   })
    //   this.packingGroups = this.masterAPIResp.packagingGroups;
    //   this.hazardClasses = this.masterAPIResp.hazardClasses;
    //   this.carTypes = this.masterAPIResp.carTypes;
    //   this.dot_aar_classes = this.masterAPIResp.dot_aar_classes;
    //   this.carBuilders = this.masterAPIResp.carBuilders;
    //   this.spinner.hide();
    //   this.selectedValues.forEach((val, i) => {
    //     this.selectedValues[i].permittedCommodity = this.permittedCommodities.filter(pc=>this.selectedValues[i].permittedCommodity
    //                                                         .find(selectedPc=>selectedPc.permittedCommoditiesID===pc.permittedCommoditiesID));
    //     this.selectedValues[i].hazardClassSelected = this.hazardClasses.find(hc => hc.hazardClassID === this.selectedValues[i].hazardClassID);
    //     this.selectedValues[i].packinggroupSelected = this.packingGroups.find(hc => hc.packinggroup === this.selectedValues[i].packingGroup);
    //     this.selectedValues[i].carbuilderSelected = this.carBuilders.find(hc => hc.carBuilderID === this.selectedValues[i].carBuilderID);
    //   })
    // })
    this.spinner.hide();
     
  }

  loading = true
  totalRecords:number=0
  selectedValuesLazyLoaded
  loadDataOnScroll(event: LazyLoadEvent) {
    this.loading = true;
     setTimeout(() => {
      //last chunk
      console.log(event, this.totalRecords)
      if (event.sortField != null && event.sortField != undefined) {
        let fieldName = event.sortField
        this.selectedValues.sort((a, b) => { return (a.fieldName < b.fieldName) ? 1 : -1 })
      }

      if (event.first === this.totalRecords - 20)
        this.selectedValuesLazyLoaded = this.loadChunk(event.first, 20);
      else
        this.selectedValuesLazyLoaded = this.loadChunk(event.first, event.rows);
      this.loading = false;
    }, 250);
  }

  loadChunk(index, length): any[] {
    let chunk: any[] = [];
    for (let i = 0; i < length; i++) {
      if (this.selectedValues[index + i])
        chunk[i] = { ...this.selectedValues[index + i] }
    }
    return chunk;
  }

  validateNumber(value) {
    let numberExp = new RegExp(/^\d*\.?\d*$/);
    return numberExp.test(value);
  }
  saveAll(saveevent) {
    this.spinner.show();
    const payload = [];
    this.selectedValues.forEach(invoice => {
      
      const railFleetClass = {
        InvoiceID: invoice.invoiceID,
        RailcarID:invoice.railcarID,
        Carstatus: invoice.carstatus,
        Owner: invoice.owner,
        TCC_Cost_Center: invoice.tcC_Cost_Center,
        GL_Account_Number: invoice.gL_Account_Number,
        GL_Account_Name: invoice.gL_Account_Name,
        Mapping_Category: invoice.mapping_Category,
        Component_Group: invoice.component_Group,
        Narrative: invoice.narrative,        
        Total: invoice.total,
        Labor_Cost:invoice.labor_Cost,
        Material_Cost:invoice.material_Cost,
        Invoicing_Company: invoice.invoicing_Company,
        BU: invoice.bu,
        // permittedCommodities: railCar.permittedCommodities,
        Car_Type: invoice.car_Type ,
        Age_of_Car: invoice.age_of_Car,
        Fleet: invoice.fleet,
        ALLT_Invoice_Ref: invoice.allT_Invoice_Ref,
        Cutoff_Date: invoice.cutoff_Date,
        Status: invoice.status,
        ModifiedBy: this.userId
      };
     payload.push(railFleetClass);
    });
      this._railFleetService.updateBulkInvoiceDetails(payload).subscribe(response => {
        this._toastr.success('Updated Successfully', 'Success!');
        this.spinner.hide();
        this.bulkEditStatus.emit(false);
      });
  }
  hideBulkEdit() {
    this.bulkEditStatus.emit(false);
  }
}
