import { Component, OnInit } from '@angular/core';
import { RailFleetService } from 'src/services/rail-fleet.service';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.css']
})
export class OverviewComponent implements OnInit {

  nextRegYrData: any;
  years = [];
  owners = [];
  expirations = [];
  allBuOptions = [];
  ownerData: any;
  expirtationData: any;
  role:any;
  userNotFound=false;
  constructor(private railFleetService: RailFleetService) { }

  ngOnInit() {
    //this.fleetVsYear();
    this.role=sessionStorage.getItem('role');
    if(!(this.role!='Admin'))
    {
      this.fleetVsOwner();
      this.fleetVsExpiration();
    }else{
      this.userNotFound=true;
      this.railFleetService.setNavmenustatus(false);
    }
   
    
  }

  fleetVsYear() {
    this.railFleetService.GetFleetVsNextRegYr().subscribe((val: any) => {
      this.nextRegYrData = val;
      // adding fleet name
      val.table2.forEach(yr => {
        const year = {fleet: yr.fleet};
        this.years.push(year);
      });
      // adding years as property
      val.table1.forEach(data => {
        this.years.forEach(yr => {
          yr[data.nextRegYr] = null;
        });
      });
      
      val.table.forEach(data => {
        const value = this.years.find(yr =>        yr.fleet === data.fleet);
        if(value) {
          value.bu = data.bu;
          value[data.nextRegYr] = data.column1;
        }
      });
    })
  }

  fleetVsOwner() {
    this.railFleetService.GetFleetVsOwner().subscribe((val: any) => {
      this.ownerData = val;
      // adding fleet name
      val.table2.forEach(ow => {
        const owner = {fleet: ow.fleet};
        this.owners.push(owner);
      });
      // adding years as property
      val.table1.forEach(data => {
        this.owners.forEach(ow => {
          ow[data.owner] = null;
        });
      });
      
      val.table.forEach(data => {
        const value = this.owners.find(ow => ow.fleet === data.fleet);
        
        if(value) {
          value.bu = data.bu;
          value[data.owner] = data.column1;
          const found = this.allBuOptions.findIndex(val => val.value === data.bu);
          found === -1 && this.allBuOptions.push({ label: data.bu, value: data.bu })
        }
      });
    })
  }
  fleetVsExpiration() {
    this.railFleetService.GetFleetVsExpiration().subscribe((val: any) => {
      this.expirtationData = val;
      // adding fleet name
      val.table2.forEach(ex => {
        const expiration = {fleet: ex.fleet};
        this.expirations.push(expiration);
      });
      // adding years as property
      val.table1.forEach(data => {
        this.expirations.forEach(ex => {
          ex[data.expiration] = null;
        });
      });
      
      val.table.forEach(data => {
        const value = this.expirations.find(ex => ex.fleet === data.fleet);
        if(value) {
          value.bu = data.bu;
          value[data.expiration] = data.column1;
        }
      });
    })
  }

}
