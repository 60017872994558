import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Global } from 'src/app/global';
import { RailFleetService } from 'src/services/rail-fleet.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {
  apiResp: any;
  globalUrlInput: any;
  globalValue: any;
  payLoad: any;
  userId: any;
  userAdmin: boolean = false;
  constructor(private global: Global, private _spinner: NgxSpinnerService, private toastr: ToastrService, private _service: RailFleetService, private router: Router) {
    this.globalValue = global;
   }

  ngOnInit() {
    this.userId = sessionStorage.getItem('userId');
    let role = sessionStorage.getItem('role');
    if(role != null && role != undefined && role != '' ) {
      if(role.includes('Admin')) {
        this.userAdmin = true;
      } else {
        this.userAdmin = false;
      }
    }  else {
      this.userAdmin = false;
    }
    if (this.userAdmin) {
    } else {
      this.router.navigate(['/login']);
    }
  }

  modify() {
    this.global.attachmentsURL = this.globalUrlInput;
    this.payLoad = {
      "id": 0,
      "url": this.globalUrlInput,
      "actionBy": "Nandha",
      "action": "Insert"
    };
    this._spinner.show();
    this._service.updateAttachmentUrl(this.payLoad).subscribe(response => {
      this.apiResp = response;
      this.toastr.success(this.apiResp, "Success!");
      this._spinner.hide();
    });
  }
}
