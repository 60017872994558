import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { RailFleetService } from 'src/services/rail-fleet.service';

@Component({
  selector: 'app-railcarurls-form',
  templateUrl: './railcarurls-form.component.html',
  styleUrls: ['./railcarurls-form.component.css']
})
export class RailcarurlsFormComponent implements OnInit {

  newUser: boolean = true;
  region = 1;
  userForm: FormGroup;
  bus:any;
  roles:any;
  // @Input() userAdmin;
  Admin=false;
  selected=[];
  record=[];
  constructor(@Inject(MAT_DIALOG_DATA) private Data: any, private dialogRef: MatDialogRef<RailcarurlsFormComponent>, private _service: RailFleetService, private _toast: ToastrService) {

  }

  ngOnInit(): void {
    this.userForm = new FormGroup({
      urlID:new FormControl('',[Validators.required]), 
      descriptionName: new FormControl('', [Validators.required]),
      url: new FormControl('', [Validators.required]),
      // region: new FormControl('Global', [Validators.required]),
      // role: new FormControl('Global Master Data Coordinator', [Validators.required])
      
    });
    
    if (this.Data.isModify && this.Data != undefined) 
    {
      this.userForm.controls.urlID.setValue(this.Data.urlID);      
      this.userForm.controls.descriptionName.setValue(this.Data.descriptionName);
      this.userForm.controls.url.setValue(this.Data.url);
      this.newUser=this.Data.newUser;
    }
  }
  public hasError = (controlName: string, errorName: string) => {
    return this.userForm.controls[controlName].hasError(errorName);
  }
  onSend() {
    if (this.userForm.valid && this.Data.isModify) {
      const acPayLoad = {
        urlID: this.userForm.controls.urlID.value,
        descriptionName: this.userForm.controls.descriptionName.value,
        url: this.userForm.controls.url.value,
        actionBy: "Prabhu",
        action: "Update"
      }
      this._service.updateRailCarUrls(acPayLoad).subscribe(res => { this._toast.success("User Successfully Updated") });
    } else{
      const acPayLoad = {
        urlID : 0,
        descriptionName: this.userForm.controls.descriptionName.value,
        url: this.userForm.controls.url.value,
        actionBy: "Prabhu",
        action: "Add"
      }
      this._service.updateRailCarUrls(acPayLoad).subscribe(res => { this._toast.success("User Created Successfully") });
    }
      this.dialogRef.close();
  }
}
