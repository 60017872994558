import { AfterViewChecked, AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmationService, FilterUtils, LazyLoadEvent, SortEvent } from 'primeng/api';

import { RailFleetService } from '../../../services/rail-fleet.service';
import * as XLSX from 'xlsx';
import { stringify } from 'querystring';
import { AllspecsComponent } from '../allspecs/allspecs.component';
import { MatDialog } from '@angular/material/dialog';
import { Table } from 'primeng/table';
import * as FileSaver from 'file-saver';
import { ToastrService } from 'ngx-toastr';
import { DialogpopupComponent } from 'src/app/common/dialogpopup/dialogpopup.component';
import { Checkbox } from 'primeng/checkbox';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

const EXCEL_EXTENSION = '.xlsx';

@Component({
  selector: 'app-invoice-selection',
  templateUrl: './invoice-selection.component.html',
  styleUrls: ['./invoice-selection.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class InvoiceSelectionComponent implements OnInit, AfterViewInit, AfterViewChecked {
  invoiceData: any;
  cols: any[] = [];
  sortedCols: any[];
  apiResp: any;
  defaultWidth: '100%';
  viewColHeight: any
  selectedCols = [];
  userId: any;
  userAdmin: boolean = false;
  filteredData: any;
  filteredFields = [];
  tempFilteredData: any;
  selectedValues: any[] = [];
  bulkEdit = false;
  permittedCommodities = [];
  rowData: any;
  masterAPIResp: any;
  pushingPC: any;
  userInfo: any;
  Arole = 0;
  @ViewChild('dtInvoiceSelection', { static: false }) dt: Table;
  @ViewChild('e', { static: false }) checkBox: Checkbox;
  tableUpdate = false;
  apires: any;
  @Input() tabtype: any;
  @Input() enableMvd: any;
  @Input() invoiceDataFromParent: any = [];
  @Output() invoiceDetails = new EventEmitter;
  status: string;
  totalRecords: number = 0
  rows = 50
  revertselectedValues: any[] = [];
  isEditMode: boolean = false;
  isEditableData: any;
  isEditableField: any;
  filterItems: any = [];

  constructor(private _railFleetService: RailFleetService, private router: Router,
    private spinner: NgxSpinnerService, private dialog: MatDialog, private _toastr: ToastrService) { }

  ngOnChanges(changes: SimpleChanges): void {
    console.log("tabchane child")
    this.invoiceData = JSON.parse(JSON.stringify(this.invoiceDataFromParent))
    this.filteredData = JSON.parse(JSON.stringify(this.invoiceData));
    this.getRailfleetInvoiceData(this.tabtype)
    this.totalRecords = this.invoiceDataFromParent.length
    this.loadDataOnScroll({ first: 0, rows: this.rows })
    this.clearFilters();
  }

  ngOnInit() {
    localStorage.removeItem('invoicecolumns');
    this.userId = sessionStorage.getItem('userId');
    let role = sessionStorage.getItem('role');
    if (role != null && role != undefined && role != '') {
      if (role.includes('Admin')) {
        this.userAdmin = true;
        this.Arole = 1;
      } else {
        this.userAdmin = false;
      }
    } else {
      this.userAdmin = false;
    }
    if (this.userId != null && this.userId != 'undefined' && this.userId != '') {
      this.spinner.show();

      this.cols = [
        { "header_id": 1, "field": "status", "header": "Response", "options": [], "width": '75px', "isShow": true, "isEditable": true, "selectedOptions": [], "serialNo": 1 },
        { "header_id": 2, "field": "railcarID", "header": "Railcar ID", "options": [], "width": '100px', "isShow": true, "isEditable": false, "selectedOptions": [], "serialNo": 2 },
        { "header_id": 3, "field": "total", "header": "Total$", "options": [], "width": '100px', "isShow": true, "isEditable": false, "selectedOptions": [], "serialNo": 11 },
        { "header_id": 5, "field": "tcC_Cost_Center", "header": "Cost Center", "options": [], "width": '100px', "isShow": true, "isEditable": true, "selectedOptions": [], "serialNo": 5 },
        { "header_id": 6, "field": "gL_Account_Number", "header": "GL Account", "options": [], "width": '91px', "isShow": true, "isEditable": true, "selectedOptions": [], "serialNo": 6 },
        { "header_id": 8, "field": "mapping_Category", "header": "Job Code Category", "options": [], "width": '200px', "isShow": true, "isEditable": false, "selectedOptions": [], "serialNo": 8 },
        { "header_id": 14, "field": "invoicing_Company", "header": "Invoicing Company", "options": [], "width": '180px', "isShow": true, "isEditable": false, "selectedOptions": [], "serialNo": 14 },
        { "header_id": 18, "field": "off_Hire_Date", "header": "Off-hire Date", "options": [], "width": '270px', "isShow": true, "isEditable": false, "selectedOptions": [], "serialNo": 18 },
        { "header_id": 19, "field": "repairDate", "header": "Repair Date", "options": [], "width": '80px', "isShow": true, "isEditable": false, "selectedOptions": [], "serialNo": 19 },
        { "header_id": 20, "field": "invoice", "header": "Invoice", "options": [], "width": '100px', "isShow": true, "isEditable": false, "selectedOptions": [], "serialNo": 20 },
        { "header_id": 21, "field": "allT_Invoice_Ref", "header": "Reference", "options": [], "width": '100px', "isShow": true, "isEditable": false, "selectedOptions": [], "serialNo": 21 }
      ];

      this.sortedCols = JSON.parse(JSON.stringify(this.cols));
      this.sortedCols = this.sortedCols.sort((a, b) => {
        return a.header > b.header ? 1 : -1;
      });
      this.cols.forEach(element => {
        this.selectedCols.push(element);
      });

      if (this.tabtype == '1') {
        let index = this.selectedCols.findIndex(d => d.field === 'status'); //find index in your array
        this.selectedCols.splice(index, 1);//remove element from array
      }
      this.viewColHeight = (window.innerHeight - 190) + "px";
      this._railFleetService.getUserInfo(this.userId).subscribe(response => {
        this.spinner.hide()
        this.userInfo = response;
        this.getRailfleetInvoiceData(this.tabtype);
      })
    } else {
      this.router.navigate(['/login']);
    }
  }

  loading = true
  invoiceDataLazy
  loadDataOnScroll(event: LazyLoadEvent) {
    //this.checkBox.checked = false;
    this.loading = true;
    setTimeout(() => {
      //last chunk
      console.log(event, this.totalRecords)
      if (event.sortField != null && event.sortField != undefined) {
        let fieldName = event.sortField;
        let sortOrder = event.sortOrder;
        this.invoiceData = this.invoiceData.sort((a, b) => {
          let value1 = null;
          let value2 = null;
          if (fieldName == 'total') {
            value1 = Number(a[fieldName]);
            value2 = Number(b[fieldName]);
          } else if (fieldName == 'cutoff_Date') {
            value1 = a[fieldName] != '' ? Date.parse(a[fieldName]) : 0
            value2 = b[fieldName] != '' ? Date.parse(b[fieldName]) : 0
          }
          else {
            value1 = a[fieldName];
            value2 = b[fieldName];
          }
          return (sortOrder === 1) ? ((value1 < value2) ? 1 : -1) : ((value1 > value2) ? 1 : -1)
        })
      }
      if (this.filterItems.length) {
        //this.runFilter();
      } else {
        this.totalRecords = this.invoiceData.length;
      }
      if (event.filters != null && event.filters != undefined && JSON.stringify(event.filters) != '{}')
        this.virtualScrollFilter(event.filters)

      if (event.first === this.totalRecords - this.rows)
        this.invoiceDataLazy = this.loadChunk(event.first, this.rows);
      else
        this.invoiceDataLazy = this.loadChunk(event.first, event.rows);
      this.loading = false;
    }, 250);
  }

  loadChunk(index, length): any[] {
    let chunk: any[] = [];
    for (let i = 0; i < length; i++) {
      if (this.invoiceData[index + i])
        chunk[i] = { ...this.invoiceData[index + i] }
    }
    return chunk;
  }
  customSort(event: SortEvent) {
    //event.data = Data to sort
    //event.mode = 'single' or 'multiple' sort mode
    //event.field = Sort field in single sort
    //event.order = Sort order in single sort
    //event.multiSortMeta = SortMeta array in multiple sort

    event.data.sort((data1, data2) => {
      let value1;
      let value2;
      if (event.field == 'total') {
        value1 = Number(data1[event.field]);
        value2 = Number(data2[event.field]);
      } else if (event.field == 'cutoff_Date') {
        value1 = data1[event.field] != '' ? Date.parse(data1[event.field]) : 0
        value2 = data2[event.field] != '' ? Date.parse(data2[event.field]) : 0
      }
      else {
        value1 = data1[event.field];
        value2 = data2[event.field];
      }

      let result = null;

      if (value1 == null && value2 != null)
        result = -1;
      else if (value1 != null && value2 == null)
        result = 1;
      else if (value1 == null && value2 == null)
        result = 0;
      else if (typeof value1 === 'string' && typeof value2 === 'string')
        result = value1.localeCompare(value2);
      else
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;

      return (event.order * result);
    });
    localStorage.setItem('invoicecolumns', JSON.stringify(this.selectedCols));
  }
  selectValue = false
  virtualSelect(value) {
    if (!this.selectValue) {
      this.selectedValues = JSON.parse(JSON.stringify(this.invoiceData));
    }
    else
      this.selectedValues = []
  }

  filteredItems = [];

  //buildFilterData(col: any) {
  //  this.loading = true;
  //  let filterObj = {
  //    fieldName: col.field,
  //    value: col.selectedOptions
  //  };
  //  this.findFilter(filterObj);
  //  this.doFilter();
  //}

  doFilter(val, field, reset?) {
    console.log('s');
    console.log(this.cols);
    if (reset) {
      this.filterItems = [];
    } else if (field != "") {
      let existingFilter = false;
      this.filterItems.forEach(fc => {
        if (fc.field == field) {
          fc.value = val;
          existingFilter = true;
        }
      });
      if (!existingFilter) {
        this.filterItems.push({
          name: this.cols.find(c => c.field == field).header,
          field: field,
          value: val
        });
      }
      if (val.length == 0) {
        this.filterItems = this.filterItems.filter(fcs => fcs.field !== field);
      }
    }
    this.invoiceData = JSON.parse(JSON.stringify(this.invoiceDataFromParent));
    if (this.filterItems.length == 0) {
      this.updateMultiSelectDD(this.invoiceData, 0);
    } else {
      this.filterItems.forEach((fc, index) => {
        let filteredData = [];
        fc.value.forEach(opt => {
          if (opt !== null && opt !== "") {
            filteredData = [...filteredData, ...this.invoiceData.filter(ocd => ocd[fc.field] === opt)];
          } else {
            filteredData = [...filteredData, ...this.invoiceData.filter(ocd => ocd[fc.field] === null || ocd[fc.field] === "")];
          }
        });
        this.invoiceData = JSON.parse(JSON.stringify(filteredData));
        this.updateMultiSelectDD(this.invoiceData, index);
      });
    }
    localStorage.setItem('columns', JSON.stringify(this.selectedCols));
  }

  //findFilter(filterObj: any) {
  //  let foundFilter = this.filterItems.find((ft: any) => ft.fieldName === filterObj.fieldName);
  //  if (foundFilter) {
  //    this.filterItems = this.filterItems.filter((ft: any) => ft.fieldName !== filterObj.fieldName);
  //  }
  //  if (!(filterObj.value.length === 0))
  //    this.addFilter(filterObj);
  //}

  //addFilter(filterObj: any) {
  //  this.filterItems.push(filterObj);
  //}

  //filterByMultiSelect(fieldName: any, value: any) {
  //  let multiSelectFilter = this.filterItems.find((ft: any) => ft.fieldName === fieldName);
  //  let multiSelectFilterItem: any = [];
  //  if (multiSelectFilter.value.length == 0) {

  //  }
  //  multiSelectFilter.value.forEach((v: any) => {
  //    if (v !== null && v !== "") {
  //      multiSelectFilterItem = [...multiSelectFilterItem, ...this.invoiceData.filter((od: any) => od[fieldName] === v)];
  //    } else {
  //      multiSelectFilterItem = [...multiSelectFilterItem, ...this.invoiceData.filter((od: any) => od[fieldName] === null || od[fieldName] === "")];
  //    }
  //  });
  //  this.invoiceData = JSON.parse(JSON.stringify(multiSelectFilterItem));
  //  this.updateMultiSelectDD(this.invoiceData, 0);
  //  this.totalRecords = this.invoiceData.length;
  //}

  updateMultiSelectDD(rowsData, index) {
    this.cols.forEach(o => {
      const filteredCol = this.filterItems.find(fc => fc.field === o.field);
      const filteredColIndex = this.filterItems.indexOf(filteredCol);
      if ((filteredColIndex == -1 || filteredColIndex > index)) {
        o.options = [];
        this.getFilterObject(rowsData, o.field).forEach(filteredobj => {
          if (filteredobj == 'Blanks') o.options.push({ label: filteredobj, value: '' });
          else o.options.push({ label: filteredobj, value: filteredobj });
        });
      }
    });

  }

  //runFilter = () => {
  //  this.filterItems.forEach((fi: any) => {
  //    this.filterByMultiSelect(fi.fieldName, fi.value)
  //  });
  //}

  // doFilter(val, col) {
  //   let multiSelectFilterItem: any = [];
  //   val.forEach((v: any) => {
  //     if(v !== null) {
  //       multiSelectFilterItem = [...multiSelectFilterItem, ...this.invoiceData.filter((od: any) => od[col.field] === v)];
  //     } else {
  //       multiSelectFilterItem = [...multiSelectFilterItem, ...this.invoiceData.filter((od: any) => od[col.field] === null || od[col.field] === "")];
  //     }
  //   });
  //   this.invoiceDataLazy = JSON.parse(JSON.stringify(multiSelectFilterItem));
  //   localStorage.setItem('invoicecolumns', JSON.stringify(this.selectedCols));

  //  // this.invoiceData = JSON.parse(JSON.stringify(this.invoiceDataFromParent))
  //   if (val.length == 0) {
  //     this.totalRecords = this.invoiceData.length
  //     this.loadDataOnScroll({ first: 0, rows: this.rows })
  //   }
  // }

  //doFilter() {
  //  this.invoiceData = JSON.parse(JSON.stringify(this.invoiceDataFromParent));
  //  this.invoiceDataLazy = Array.from({ length: this.invoiceData.length });
  //  this.loadDataOnScroll({ first: 0, rows: this.rows })
  //}

  virtualScrollFilter(filters) {

    this.filteredItems = []
    let data: any = []
    let searchArray = this.invoiceData
    console.log('virtualScrollFilter', searchArray, filters);
    for (var key in filters) {
      filters[key].value.forEach(element => {
        let dummyFilterData = searchArray.filter(x => typeof x[key] === 'number' ? x[key] === element : x[key].includes(element))
        dummyFilterData.forEach(ele => {
          if (!data.includes(ele))
            data.push(ele)
        });

      });
      let arr1 = searchArray.filter(e => {
        return data.some(item => item == e)
      })
      searchArray = arr1
      data = []
    }
    this.invoiceData = searchArray
    this.totalRecords = this.invoiceData.length
  }

  clearFilters() {
    this.filterItems = [];
    this.selectedCols = this.cols;
    if (this.tabtype == '1') {
      this.selectedCols = this.cols.filter(d => d.field !== 'status'); //find index in your array
    }
    this.selectedCols.forEach(col => {
      col.selectedOptions = [];
    });
    this.doFilter([],"");
  }

  ngAfterViewInit(): void {
    let selectedVal = [];
    if (this.dt) {
      if (localStorage.getItem('invoicecolumns')) {
        this.selectedCols = JSON.parse(localStorage.getItem('invoicecolumns'));
        this.cols.forEach(element => {
          this.selectedCols.forEach(elm => {
            if (element.field === elm.field) {
              if (elm.selectedOptions.length != 0) {
                element.selectedOptions = elm.selectedOptions;
                selectedVal.push(element);
              } else {
                selectedVal.push(element);
              }

            }

          });
        });
        this.selectedCols = selectedVal;
      };
    }
  }
  ngAfterViewChecked(): void {
    let selectedVals = [];
    if (this.dt && this.tableUpdate) {
      this.tableUpdate = false;
      if (localStorage.getItem('invoicecolumns')) {
        this.selectedCols = JSON.parse(localStorage.getItem('invoicecolumns'));
        this.cols.forEach(element => {
          this.selectedCols.forEach(elm => {
            if (element.field === elm.field) {
              selectedVals.push(element);
            }

          });
        });
        this.selectedCols = selectedVals;
      };
    }
  }
  onChangeDDValue(value) {
    this.sortColumns();
  }

  showHideData(e) {
    this.cols = e;
  }

  sortColumns() {
    this.selectedCols = this.selectedCols.sort((a, b) => {
      return a.serialNo - b.serialNo;
    })

  }

  getRailfleetInvoiceData(tabtype) {

    this.invoiceData = JSON.parse(JSON.stringify(this.invoiceDataFromParent));
    this.filteredData = JSON.parse(JSON.stringify(this.invoiceData));

    this.cols.forEach(o => {
      o.selectedOptions = [];
      const selectedOptions = [];
      if (o.field === 'total') {
        o.options = [];
      } else {
        o.options = [];
        //o.options = [{ label: 'Blanks', value: null }];
      }
      this.getFilterObject(this.invoiceData, o.field).forEach(filteredobj => {
        if (!!o.selectedOptions && o.selectedOptions.length > 0 && o.selectedOptions.includes(filteredobj)) {
          selectedOptions.push(filteredobj)
        }
        if (filteredobj == 'Blanks')
          o.options.push({ label: filteredobj, value: '' });
        else
          o.options.push({ label: filteredobj, value: filteredobj });
        //o.options.push({ label: filteredobj, value: filteredobj })
      });
      if (o.field === 'total') {
        o.options = o.options.sort((a, b) => {
          return ((Number(a.value) < Number(b.value)) ? -1 : 1)
        });
        //o.options.unshift({ label: 'Blanks', value: null });
      }
      o.selectedOptions = selectedOptions;
    });

  }
  bulkStatusChange(action: string) {
    //   this.confirmationService.confirm({
    //     message: 'Are you sure that you want to delete this Account?',
    //     accept: () => {
    //       console.log('accept')
    //         // this.service.deleteAcccountDetails(this.accountDetailsObj).subscribe(res=>{
    //         //   this.service.displayToastr(res);
    //         //   this.getAllAccountDetails();
    //         //   this.onClose();
    //         // },error=>{
    //         //   this.service.displayToastr(error);
    //         // });
    //     }
    // });
    let dialogref = this.dialog.open(DialogpopupComponent, { data: { message: 'Are you sure that you want to proceed?', title: action + ' Confirmation' } })
    dialogref.afterClosed().subscribe(res => {
      if (res) {
        const payload = [];

        if (action == 'Revert') {
          this.status = null;
        }
        else {
          this.status = action;
        }
        this.selectedValues.forEach(invoice => {

          const railFleetinvoice = {
            InvoiceID: invoice.invoiceID,
            RailcarID: invoice.railcarID,
            Carstatus: invoice.carstatus,
            Owner: invoice.owner,
            TCC_Cost_Center: invoice.tcC_Cost_Center,
            GL_Account_Number: invoice.gL_Account_Number,
            GL_Account_Name: invoice.gL_Account_Name,
            Mapping_Category: invoice.mapping_Category,
            Component_Group: invoice.component_Group,
            Narrative: invoice.narrative,
            Total: invoice.total,
            Labor_Cost: invoice.labor_Cost,
            Material_Cost: invoice.material_Cost,
            Invoicing_Company: invoice.invoicing_Company,
            BU: invoice.bu,
            // permittedCommodities: railCar.permittedCommodities,
            Car_Type: invoice.car_Type,
            Age_of_Car: invoice.age_of_Car,
            Fleet: invoice.fleet,
            ALLT_Invoice_Ref: invoice.allT_Invoice_Ref,
            Cutoff_Date: invoice.cutoff_Date,
            Status: this.status,
            ModifiedBy: this.userId,
            RepairDate: invoice.repairDate,
            Invoice: invoice.invoice
          };
          payload.push(railFleetinvoice);
        });
        console.log(payload)
        this._railFleetService.reviewInvoiceDetails(payload).subscribe(response => {
          this._toastr.success('Updated Successfully', 'Success!');
          this.checkBox.checked = false;
          console.log(this.tabtype)
          this.selectedValues = []
          this.getRailfleetInvoiceData(this.tabtype)
          this.invoiceDetails.emit(true)
          this.spinner.hide();
          this.clearFilters();
        });
      }
    });



  }
  sendToMVD() {
    let dialogref = this.dialog.open(DialogpopupComponent, { data: { message: 'Are you sure that you want to proceed? This action will remove all the invoices', title: 'Send report to MVD Account team.' } })
    dialogref.afterClosed().subscribe(res => {
      if (res) {

        this._railFleetService.updateMvdAccountUsers(this.userId).subscribe(response => {
          this._toastr.success('Sent Successfully', 'Success!');
          this.invoiceData = [];
          this.getRailfleetInvoiceData(this.tabtype)
          this.spinner.hide();

        });
      }
    })
  }
  //getFilterObject(fullObj, key) {
  //  const uniqChk = [];
  //  fullObj.filter((obj) => {
  //    if (!uniqChk.includes(obj[key])) {
  //      if (obj[key] !== null && obj[key] !== '')
  //        uniqChk.push(obj[key]);
  //    }
  //    return obj;
  //  });
  //  uniqChk.sort();
  //  return uniqChk;
  //}
  getFilterObject(fullObj, key) {
    const uniqChk = [];
    let blanks = false;
    fullObj.filter((obj) => {
      if (!uniqChk.includes(obj[key])) {
        if (obj[key] !== null && obj[key] !== '')
          uniqChk.push(obj[key]);
        else if (!uniqChk.includes('Blanks')) {
          blanks = true;
        }
      }
      return obj;
    });
    if (blanks) uniqChk.unshift('Blanks');
    return uniqChk;
  }
  onnSelectRow(rowData, type) {
    this.router.navigate(['rail-car/info'], { state: { data: { cols: this.cols, rowData: rowData, selectionType: type } } });
  }
  filterData(value, filter) {
    if (value == '') {
      if (this.filteredFields.length) {
        this.filteredFields = this.filteredFields.filter(ff => ff.fieldName !== filter);
      } else {
        this.filteredFields = [];
      }
    } else {
      let foundField = this.filteredFields.filter(ff => ff.fieldName == filter);
      if (foundField.length) {
        this.filteredFields.forEach(ff => {
          if (ff.fieldName == filter) {
            ff.value = value;
          }
        })
      } else {
        let fieldObj = {
          fieldName: filter,
          value: value
        };
        this.filteredFields.push(fieldObj);
      }
    }
    if (this.filteredFields.length) {
      let temp = this.invoiceData;
      this.filteredFields.forEach(ff => {
        temp = temp.filter(fd => fd[ff.fieldName].toUpperCase().includes(ff.value.toUpperCase()));
      })
      this.filteredData = temp;
    } else {
      this.filteredData = JSON.parse(JSON.stringify(this.invoiceData));
    }
    this.tempFilteredData = JSON.parse(JSON.stringify(this.filteredData));
  }

  showAllSpecsDetails(element: any) {
    this._railFleetService.emailAllSpecs(element.railcarID).subscribe((res: any) => {
      this.apires = res;
      const blob = this.b64toBlob(this.apires, 'application/pdf');
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl, '_blank');
    })
  }
  b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  exportExcel() {
    let data = this.invoiceData;
    let tFilteredData = JSON.parse(JSON.stringify(data));
    this.exportAsExcelFile(tFilteredData, 'RailFleetInvoice', 'sheet1', this.selectedCols)
  }
  exportAsExcelFile(json: any[], excelFileName: string, sheetName: string, columnValues: any[]): void {
    var fieldValues: any[] = columnValues.map(field => field.field);
    var headerValues: string[] = columnValues.map(header => header.header);
    var displayedJson: any[] = [];
    if (json != null && json.length > 0) {
      for (let originalValue of json) {
        var jsonObject: any = {};
        for (let fieldValue of fieldValues) {
          jsonObject[fieldValue] = originalValue[fieldValue];
        }
        var jsonString = JSON.stringify(jsonObject);
        displayedJson.push(JSON.parse(jsonString));
      }
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(displayedJson);
      var range = XLSX.utils.decode_range(worksheet['!ref']);
      for (var C = range.s.c; C <= range.e.c; ++C) {
        var address = XLSX.utils.encode_col(C) + "1"; // <-- first row, column number C
        if (!worksheet[address]) continue;
        worksheet[address] = {
          v: headerValues[C],
          t: 's',
          s: {
            alignment: { textRotation: 90 },
            font: { bold: true }
          }
        };
      }
      XLSX.utils.sheet_add_aoa(worksheet, [[]], { origin: -1 });
      const workbook: XLSX.WorkBook = { Sheets: { [sheetName]: worksheet }, SheetNames: [sheetName] };
      const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, excelFileName);
    }

  }
  saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
  }
  editBulk() {
    this.bulkEdit = true;
  }
  reset() {
    this.invoiceData = JSON.parse(JSON.stringify(this.invoiceDataFromParent))
    this.totalRecords = this.invoiceData.length
    console.log("rest")
    this.filteredItems = []
    if (localStorage.getItem('invoicecolumns')) {
      this.selectedCols = this.cols
      if (this.tabtype == '1') {
        let index = this.selectedCols.findIndex(d => d.field === 'status'); //find index in your array
        this.selectedCols.splice(index, 1);//remove element from array
      }
      this.selectedCols.forEach(col => {
        col.selectedOptions = [];
      });
    }
    this.doFilter([], '', true);
    this.updateMultiSelectDD(this.invoiceData, 0);
    localStorage.removeItem('invoicecolumns');
  }
  bulkEditStatus(status) {
    this.selectedCols = [];
    this.cols.forEach(element => {
      // if(element.field!='hazardClass' && element.field!='packingGroup')
      this.selectedCols.push(element);
    });

    if (this.tabtype == '1') {
      let index = this.selectedCols.findIndex(d => d.field === 'status'); //find index in your array
      this.selectedCols.splice(index, 1);//remove element from array
    }
    this.bulkEdit = status;
    if (!this.bulkEdit) {
      this.tableUpdate = true;
    }
    this.getRailfleetInvoiceData(this.tabtype);
    this.selectedValues = [];
  }

  openEditMode(rData, fieldName) {
    let fSelectedRow = this.selectedValues.find(sv1 => sv1.invoiceID === rData.invoiceID);
    if (fSelectedRow) {
      this.isEditableData = rData;
      this.isEditableField = fieldName;
      this.isEditMode = true;
    }
  }

  checkTheKey(e) {
    console.log(e);
    if (e.keyCode === 27) {
      this.isEditableData = null;
      this.isEditableField = '';
      this.isEditMode = false;
    }
  }

  updateSelectedRows(keyCode, value, fieldName) {
    this.selectedValues.forEach(sv => {
      this.invoiceDataLazy.map(rd => {
        if (rd.invoiceID === sv.invoiceID) {
          rd[fieldName] = value;
          sv[fieldName] = value;
        }
      })
    });
    this.updateBulkInvoiceDetails();
    this.isEditableData = null;
    this.isEditableField = '';
    this.isEditMode = false;
  }

  updateBulkInvoiceDetails() {
    this.spinner.show();
    const payload = [];
    this.selectedValues.forEach(invoice => {
      const railFleetClass = {
        InvoiceID: invoice.invoiceID,
        RailcarID: invoice.railcarID,
        Carstatus: invoice.carstatus,
        Owner: invoice.owner,
        TCC_Cost_Center: invoice.tcC_Cost_Center,
        GL_Account_Number: invoice.gL_Account_Number,
        GL_Account_Name: invoice.gL_Account_Name,
        Mapping_Category: invoice.mapping_Category,
        Component_Group: invoice.component_Group,
        Narrative: invoice.narrative,
        Total: invoice.total,
        Labor_Cost: invoice.labor_Cost,
        Material_Cost: invoice.material_Cost,
        Invoicing_Company: invoice.invoicing_Company,
        BU: invoice.bu,
        Car_Type: invoice.car_Type,
        Age_of_Car: invoice.age_of_Car,
        Fleet: invoice.fleet,
        ALLT_Invoice_Ref: invoice.allT_Invoice_Ref,
        Cutoff_Date: invoice.cutoff_Date,
        Status: invoice.status,
        ModifiedBy: this.userId,
        RepairDate: invoice.repairDate,
        Invoice: invoice.invoice
      };
      payload.push(railFleetClass);
    });
    this._railFleetService.reviewInvoiceDetails(payload).subscribe(response => {
      this._toastr.info('Data Saved', 'Success!');
      this.invoiceDetails.emit(true);
      this.clearFilters();
      this.spinner.hide();
    });
  }
}
