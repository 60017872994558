import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { RailFleetService } from 'src/services/rail-fleet.service';

@Component({
  selector: 'app-documenturls-form',
  templateUrl: './documenturls-form.component.html',
  styleUrls: ['./documenturls-form.component.css']
})
export class DocumenturlsFormComponent implements OnInit {
  newUser: boolean = true;
  region = 1;
  userForm: FormGroup;
  bus:any;
  roles:any;
  // @Input() userAdmin;
  Admin=false;
  selected=[];
  record=[];
  constructor(@Inject(MAT_DIALOG_DATA) private Data: any, private dialogRef: MatDialogRef<DocumenturlsFormComponent>, private _service: RailFleetService, private _toast: ToastrService) {

  }

  ngOnInit(): void {
    this.userForm = new FormGroup({
      dUrlID:new FormControl('',[Validators.required]), 
      descriptionName: new FormControl('', [Validators.required]),
      url: new FormControl('', [Validators.required]),      
    });
    
    if (this.Data.isModify && this.Data != undefined) 
    {
      this.userForm.controls.dUrlID.setValue(this.Data.dUrlID);      
      this.userForm.controls.descriptionName.setValue(this.Data.descriptionName);
      this.userForm.controls.url.setValue(this.Data.url);
      this.newUser=this.Data.newUser;
    }
  }
  public hasError = (controlName: string, errorName: string) => {
    return this.userForm.controls[controlName].hasError(errorName);
  }
  onSend() {
    if (this.userForm.valid && this.Data.isModify) {
      const acPayLoad = {
        dUrlID: this.userForm.controls.dUrlID.value,
        descriptionName: this.userForm.controls.descriptionName.value,
        url: this.userForm.controls.url.value,
        actionBy: "Prabhu",
        action: "Update"
      }
      this._service.updateDocumentsUrls(acPayLoad).subscribe(res => { this._toast.success("Document Link Successfully Updated") });
    } else{
      const acPayLoad = {
        dUrlID : 0,
        descriptionName: this.userForm.controls.descriptionName.value,
        url: this.userForm.controls.url.value,
        actionBy: "Prabhu",
        action: "Add"
      }
      this._service.updateDocumentsUrls(acPayLoad).subscribe(res => { this._toast.success("Document Link Created Successfully") });
    }     
     
      this.dialogRef.close();
  }

}
