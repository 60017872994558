import { Component, OnInit, SimpleChanges, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { FilterUtils } from 'primeng/api';
import { RailFleetService } from '../../../services/rail-fleet.service';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { ToastrService } from 'ngx-toastr';
import { element } from 'protractor';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { EMLINK } from 'constants';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

const EXCEL_EXTENSION = '.xlsx';

@Component({
  selector: 'app-rail-car-info-edit',
  templateUrl: './rail-car-info-edit.component.html',
  styleUrls: ['./rail-car-info-edit.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class RailCarInfoEditComponent implements OnInit {
  @Input() selectedValues: any;
  @Input() selectedCols: any[];
  @Output() bulkEditStatus = new EventEmitter<boolean>();
  activeCarsData: any;
  cols: any[];
  selected=[];
  apiResp: any;
  defaultWidth: '100%';
  viewColHeight: any;
  userId: any;
  userAdmin: boolean = false;
  filteredData: any;
  filteredFields = [];
  tempFilteredData: any;
  masterAPIResp: any;
  permittedCommodities: any;
  packingGroups: any;
  hazardClasses: any;
  carTypes: any;
  dot_aar_classes: any;
  carBuilders: any;

  constructor(private _railFleetService: RailFleetService, private router: Router,private spinner: NgxSpinnerService, private _toastr: ToastrService) { }

  ngOnInit() {
    this.userId = sessionStorage.getItem('userId');
    let role = sessionStorage.getItem('role');
    if(role != null && role != undefined && role != '' ) {
      if(role.includes('Admin')) {
        this.userAdmin = true;
      } else {
        this.userAdmin = false;
      }
    }  else {
      this.userAdmin = false;
    }
    this.getAllMasterData()
    if (this.userId != null && this.userId != 'undefined' && this.userId != '') {
      this.spinner.show();
      this.cols = [
        { field: 'railcarID', header: 'Railcar ID', options:[], isShow: true, width: this.defaultWidth, isEditable: false, serialNo: 1,selectedOptions:[] },
        { field: 'carStatus', header: 'Car Status',options:[], isShow: true, width: this.defaultWidth, isEditable: true, serialNo: 2 ,selectedOptions:[]},
        { field: 'dueTest', header: 'Due Test',options:[], isShow: true, width: this.defaultWidth, isEditable: false, serialNo: 3,selectedOptions:[] },
        //{ field: 'currentRegCompleted', header: 'Current Reg.Completed(ALLT Data)', isShow: true, width: this.defaultWidth, isEditable: false, serialNo: 4 },
        { field: 'originalRCID', header: 'Original RC ID',options:[], isShow: true, width: this.defaultWidth, isEditable: true, serialNo: 4,selectedOptions:[] },
        { field: 'bu', header: 'BU',options:[], isShow: true, width: this.defaultWidth, isEditable: false, serialNo: 5,selectedOptions:[] },
        { field: 'tcc', header: 'TCC',options:[], isShow: true, width: this.defaultWidth, isEditable: false, serialNo: 6,selectedOptions:[]},
        { field: 'contract', header: 'Contract',options:[], isShow: true, width: this.defaultWidth, isEditable: false, serialNo: 7,selectedOptions:[]},
        { field: 'fixedAsset', header: 'Fixed Asset',options:[], isShow: true, width: this.defaultWidth, isEditable: false, serialNo: 8,selectedOptions:[]},
        { field: 'locationCode', header: 'Location Code',options:[], isShow: true, width: this.defaultWidth, isEditable: false, serialNo: 9,selectedOptions:[] },
        { field: 'maintenancePlant', header: 'Maintenance Plant',options:[], isShow: true, width: this.defaultWidth, isEditable: false, serialNo: 10,selectedOptions:[] },
        { field: 'assignedLocation', header: 'Assigned Location',options:[], isShow: true, width: this.defaultWidth, isEditable: false, serialNo: 11,selectedOptions:[] },
        { field: 'maintenancePlantLocation', header: 'Maintenance Plant Location',options:[], isShow: true, width: this.defaultWidth, isEditable: false, serialNo: 12,selectedOptions:[] },
        { field: 'fleet', header: 'Fleet',options:[], isShow: true, width: this.defaultWidth, isEditable: false, serialNo: 13,selectedOptions:[] },
        
        { field: 'permittedCommodity',subfield:'permittedcommodities', header: 'Permitted Commodities',options:[], isShow: true, width: this.defaultWidth, isEditable: false, serialNo: 14,selectedOptions:[]},
        { field: 'packingGroup', header: 'Packing Group',options:[], isShow: true, width: this.defaultWidth, isEditable: true, serialNo: 15,selectedOptions:[]},
        { field: 'hazardClass', header: 'Hazard Class',options:[], isShow: true, width: this.defaultWidth, isEditable: true, serialNo: 16,selectedOptions:[]},
        //{ field: 'jacketed', header: 'Jacketed (SPECs data)', isShow: true, width: this.defaultWidth, isEditable: true, serialNo: 16 },
        { field: 'carType', header: 'Car Type',options:[], isShow: true, width: this.defaultWidth, isEditable: false, serialNo: 17,selectedOptions:[]},
        { field: 'originalCertofConstruction', header: 'Original Cert. of Construction',options:[], isShow: true, width: this.defaultWidth, isEditable: true, serialNo: 18,selectedOptions:[] },
        { field: 'doT_AAR_Class', header: 'DOT/AAR Class',options:[], isShow: true, width: this.defaultWidth, isEditable: false, serialNo: 19,selectedOptions:[]},
        { field: 'carBuilder', header: 'Car Builder',options:[], isShow: true, width: this.defaultWidth, isEditable: false, serialNo: 20,selectedOptions:[]},
        { field: 'yearBuilt', header: 'Year Built',options:[], isShow: true, width: this.defaultWidth, isEditable: false, serialNo: 21,selectedOptions:[]},
        { field: 'ageofCar', header: 'Age of Car',options:[], isShow: true, width: this.defaultWidth, isEditable: false, serialNo: 22,selectedOptions:[]},
        { field: 'onHire', header: 'On Hire', options: [], isShow: true, width: this.defaultWidth, isEditable: true, serialNo: 23,selectedOptions:[]},
        { field: 'owner', header: 'Owner',options:[], isShow: true, width: this.defaultWidth, isEditable: false, serialNo: 24,selectedOptions:[]},
        { field: 'rider', header: 'Rider',options:[], isShow: true, width: this.defaultWidth, isEditable: false, serialNo: 25,selectedOptions:[]},
        { field: 'effective', header: 'Effective',options:[], isShow: true, width: this.defaultWidth, isEditable: false, serialNo: 26,selectedOptions:[]},
        { field: 'expiration', header: 'Expiration',options:[], isShow: true, width: this.defaultWidth, isEditable: false, serialNo: 27,selectedOptions:[]},
        { field: 'monthlyDepreciation', header: 'Monthly Depreciation',options:[], isShow: true, width: this.defaultWidth, isEditable: false, serialNo: 28,selectedOptions:[] },
        // { field: 'rent', header: 'Rent', isShow: true, width: this.defaultWidth, isEditable: true, serialNo: 29 },
        // { field: 'rep_Maint', header: 'Rep & Maint.)', isShow: true, width: this.defaultWidth, isEditable: true, serialNo: 30 },
        { field: 'rent', header: 'Monthly Rent',options:[], isShow: true, width: this.defaultWidth, isEditable: false, serialNo: 29,selectedOptions:[]},
        { field: 'leaseTerm', header: 'Lease Term. Mns',options:[], isShow: true, width: this.defaultWidth, isEditable: false, serialNo: 30,selectedOptions:[]},
        { field: 'term', header: 'Term. yr',options:[], isShow: true, width: this.defaultWidth, isEditable: false, serialNo: 31,selectedOptions:[] },
        { field: 'tareWeightLBS', header: 'Tare Weight LBS',options:[], isShow: true, width: this.defaultWidth, isEditable: false, serialNo: 32,selectedOptions:[]},
        { field: 'maxGrossWeight', header: 'Max Gross Weight',options:[], isShow: true, width: this.defaultWidth, isEditable: false, serialNo: 33,selectedOptions:[]},
        { field: 'comments', header: 'Comments',options:[], isShow: true, width: this.defaultWidth, isEditable: true, serialNo: 34,selectedOptions:[]},
        // { field: 'release', header: 'Release',options:[], isShow: true, width: this.defaultWidth, isEditable: true, serialNo: 33,selectedOptions:[]},
      ];
      this.cols.forEach(element => {
        this.selectedCols.forEach(elm => {
          if (element.field === elm.field) {

            if(elm.selectedOptions.length!=0)
            {
              element.selectedOptions=elm.selectedOptions;
            this.selected.push(element);
            }else{
              this.selected.push(element);
            }
          } 
          
        });
      });
      this.selectedCols=this.selected;
      this.viewColHeight = (window.innerHeight - 190) + "px";
      FilterUtils['custom'] = (value, filter): boolean => {
        if (filter === undefined || filter === null || filter.trim() === '') {
          return true;
        }

        if (value === undefined || value === null) {
          return false;
        }

        return parseInt(filter) > value;
      }
    } else {
      this.router.navigate(['/login']);
    }
  }

  onChangeDDValue(value) {
    this.sortColumns(value);
  }

  showHideData(e) {
    this.cols = e;
  }

  sortColumns(value) {
    this.selectedCols = this.selectedCols.sort((a, b) => {
      return a.serialNo - b.serialNo;
    })
  }


  onnSelectRow(rowData, type) {
    this.router.navigate(['rail-car/info'], { state: { data: {cols: this.cols, rowData: rowData, selectionType: type} } });
  }

  filterData(value, filter) {
    if (value == '') {
      if (this.filteredFields.length) {
        this.filteredFields = this.filteredFields.filter(ff => ff.fieldName !== filter);
      } else {
        this.filteredFields = [];
      }
    } else {
      let foundField = this.filteredFields.filter(ff => ff.fieldName == filter);
      if (foundField.length) {
        this.filteredFields.forEach(ff => {
          if (ff.fieldName == filter) {
            ff.value = value;
          }
        })
      } else {
        let fieldObj = {
          fieldName: filter,
          value: value
        };
        this.filteredFields.push(fieldObj);
      }
    }
    if (this.filteredFields.length) {
      let temp = this.activeCarsData;
      this.filteredFields.forEach(ff => {
        temp = temp.filter(fd => fd[ff.fieldName].toUpperCase().includes(ff.value.toUpperCase()));
      })
      this.filteredData = temp;
    } else {
      this.filteredData = JSON.parse(JSON.stringify(this.activeCarsData));
    }
    this.tempFilteredData = JSON.parse(JSON.stringify(this.filteredData));
  }

  exportExcel() {
    let tFilteredData = JSON.parse(JSON.stringify(this.filteredData));
    tFilteredData.forEach(ac => {
      let pcValue = "";
      if(ac.permittedCommodity.length > 0) {
        ac.permittedCommodity.forEach(pc => {
          pcValue = pcValue + pc.permittedcommodities + ", "
        });
        ac.permittedCommodity = pcValue;
      }
    }) 
    this.exportAsExcelFile(tFilteredData, 'RailCarFleet', 'sheet1', this.cols)
  }


  exportAsExcelFile(json: any[], excelFileName: string, sheetName: string, columnValues: any[]): void {
    var fieldValues: any[] = columnValues.map(field => field.field);
    var headerValues: string[] = columnValues.map(header => header.header);
    var displayedJson: any[] = [];
    if (json != null && json.length > 0) {
      for (let originalValue of json) {
        var jsonObject: any = {};
        for (let fieldValue of fieldValues) {
          jsonObject[fieldValue] = originalValue[fieldValue];
        }
        var jsonString = JSON.stringify(jsonObject);
        displayedJson.push(JSON.parse(jsonString));
      }
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(displayedJson);
      var range = XLSX.utils.decode_range(worksheet['!ref']);
      for (var C = range.s.c; C <= range.e.c; ++C) {
        var address = XLSX.utils.encode_col(C) + "1"; // <-- first row, column number C
        if (!worksheet[address]) continue;
        worksheet[address] = {
          v: headerValues[C],
          t: 's',
          s: {
            alignment: { textRotation: 90 },
            font: { bold: true }
          }
        };
      }
      XLSX.utils.sheet_add_aoa(worksheet, [[]], { origin: -1 });
      const workbook: XLSX.WorkBook = { Sheets: { [sheetName]: worksheet }, SheetNames: [sheetName] };
      const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, excelFileName);
    }

  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
  }
  editBulk() {
    const selectedCheckbox = this.activeCarsData.filter(val => val[0]);
  }
  getAllMasterData() {
    this.spinner.show();
    this._railFleetService.getRailMasterData().subscribe(response => {
      this.masterAPIResp = response;
      this.permittedCommodities = this.masterAPIResp.permittedCommodities;
      this.permittedCommodities.forEach(pc => {
        // pc.railcarID = this.rowData.railcarID;
        pc.permittedCommoditiesDesc = null;
      })
      this.packingGroups = this.masterAPIResp.packagingGroups;
      this.hazardClasses = this.masterAPIResp.hazardClasses;
      this.carTypes = this.masterAPIResp.carTypes;
      this.dot_aar_classes = this.masterAPIResp.dot_aar_classes;
      this.carBuilders = this.masterAPIResp.carBuilders;
      this.spinner.hide();
      this.selectedValues.forEach((val, i) => {
        this.selectedValues[i].permittedCommodity = this.permittedCommodities.filter(pc=>this.selectedValues[i].permittedCommodity
                                                            .find(selectedPc=>selectedPc.permittedCommoditiesID===pc.permittedCommoditiesID));
        this.selectedValues[i].hazardClassSelected = this.hazardClasses.find(hc => hc.hazardClassID === this.selectedValues[i].hazardClassID);
        this.selectedValues[i].packinggroupSelected = this.packingGroups.find(hc => hc.packinggroup === this.selectedValues[i].packingGroup);
        this.selectedValues[i].carbuilderSelected = this.carBuilders.find(hc => hc.carBuilderID === this.selectedValues[i].carBuilderID);
      })
    })
  }
  validateNumber(value) {
    let numberExp = new RegExp(/^\d*\.?\d*$/);
    return numberExp.test(value);
  }
  saveAll() {
    this.spinner.show();
    const payload = [];
    this.selectedValues.forEach(railCar => {
      
      const railFleetClass = {
        railcarID: railCar.railcarID,
        carStatus:railCar.carStatus,
        dueTest: railCar.dueTest,
        nextRegYr: railCar.nextRegYr,
        currentRegCompleted: railCar.currentRegCompleted,
        originalRCID: railCar.originalRCID,
        bu: railCar.bu,
        tcc: railCar.tcc,
        contract: railCar.contract,
        fixedAsset: railCar.fixedAsset,        
        locationCode: railCar.locationCode,
        sortField:railCar.sortField,
        maintenancePlant:railCar.maintenancePlant,
        assignedLocation: railCar.assignedLocation,
        fleet: railCar.fleet,
        // permittedCommodities: railCar.permittedCommodities,
        packingGroup: railCar.packinggroupSelected ? railCar.packinggroupSelected.packinggroup : null,
        hazardClass: railCar.hazardClassSelected ? railCar.hazardClassSelected.hazardclass : null,
        jacketed: railCar.jacketed,
        carType: railCar.carType,
        originalCertofConstruction: railCar.originalCertofConstruction,
        doT_AAR_Class: railCar.doT_AAR_Class,
        carBuilder: railCar.carbuilderSelected ? railCar.carbuilderSelected.carbuilder: '',
        yearBuilt: railCar.yearBuilt,
        ageofCar: railCar.ageofCar,
        onHire: railCar.onHire,
        owner: railCar.owner,
        rider: railCar.rider,
        effective: railCar.effective,
        expiration: railCar.expiration,
        monthlyDepreciation: railCar.monthlyDepreciation,
        rent: railCar.rent,
        rep_Maint: railCar.rep_Maint,
        rentalAgreement: railCar.rentalAgreement.toString(),
        leaseTerm: railCar.leaseTerm,
        term: railCar.term,
        tareWeightLBS: railCar.tareWeightLBS,
        maxGrossWeight: railCar.maxGrossWeight,
        comments: railCar.comments,
        release: railCar.release,
        lastUpdatedDate: railCar.lastUpdatedDate,
        permittedCommoditiesID: railCar.permittedCommoditiesID, //
        packingGroupID: railCar.packinggroupSelected ? railCar.packinggroupSelected.packingGroupID : null,
        hazardClassID: railCar.hazardClassSelected ? railCar.hazardClassSelected.hazardClassID : null,
        carBuilderID: railCar.carbuilderSelected ? railCar.carbuilderSelected.carBuilderID : null,
        modifiedBy: "Nandha",
        permittedCommodity: railCar.permittedCommodity
      };
      payload.push(railFleetClass);
    });
      this._railFleetService.updaterailBulkfleetdetails(payload).subscribe(response => {
        this._toastr.success('Updated Successfully', 'Success!');
        this.spinner.hide();
        this.bulkEditStatus.emit(false);
      });
  }
  hideBulkEdit() {
    this.bulkEditStatus.emit(false);
  }
}
