import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialogrequest',
  templateUrl: './dialogrequest.component.html',
  styleUrls: ['./dialogrequest.component.css']
})
export class DialogrequestComponent
{

  constructor(@Inject(MAT_DIALOG_DATA) public data: {
    message: string,
   title: string
 }) { }

}
