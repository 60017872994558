import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Message, MessageService } from 'primeng/api';
import { RailFleetService } from 'src/services/rail-fleet.service';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent {
  userId = "";
  userInfo: any;
  // notifications = [];
  msgs: Message[] = [];
  constructor(private router: Router,  private toastr: ToastrService, private spinner: NgxSpinnerService, private _service: RailFleetService,
    private messageService: MessageService) {
  }
  ngOnInit() {  
    this._service.getCurrentNotifications().subscribe((response: any) => {
      // this.notifications = response;
      response.forEach(notification => {
        
        this.msgs.push({severity:'info', summary: '', detail: notification.notificationMessage});
      });
      this.messageService.addAll(this.msgs);
    })
    this.userId = sessionStorage.getItem('userId');
    if (this.userId != null && this.userId != 'undefined' && this.userId != '') {
      this.spinner.show();
      //this.unAuthorised = true;
      this._service.getUserInfo(this.userId).subscribe(response => {
        this.userInfo = response;
        sessionStorage.setItem('email', this.userInfo[0].email);
        if (this.userInfo[0].roleName.includes('Admin')) {
          sessionStorage.setItem('role', 'Admin');
        }
        this.spinner.hide();
      });
    } else {
      this.router.navigate(['/login']);
    }  
  }

  navigateTo(routeValue) {
    this.router.navigate([routeValue]);
  }
  
}
