import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpConfigInterceptor } from './_helpers/httpconfig.interceptor';
import { CookieService } from 'ngx-cookie-service';
import { ToastrModule } from 'ngx-toastr';
import { NgxSpinnerModule } from 'ngx-spinner';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { HomeComponent } from './components/home/home.component';
import { LoginTestComponent } from './components/login-test/login-test.component';
import { LoginComponent } from './components/login/login.component'
import { UserScreenComponent } from './components/users/user-screen/user-screen.component';
import { UserPopupComponent } from './components/users/user-popup/user-popup.component';
import { LogoutComponent } from './components/logout/logout.component';
import { RailCarInformationComponent } from './components/rail-car-information/rail-car-information.component';
import { RailCarInfoSelectionComponent } from './components/rail-car-info-selection/rail-car-info-selection.component';
import { RailCarInfoEditComponent } from './components/rail-car-info-edit/rail-car-info-edit.component';
import { RailCarModifyComponent } from './components/rail-car-modify/rail-car-modify.component';
import { ViewAttachmentsComponent } from './components/view-attachments/view-attachments.component';
import { InvoiceInformationComponent } from './components/invoice-information/invoice-information.component';
import { InvoiceSelectionComponent } from './components/invoice-selection/invoice-selection.component';
import { InvoiceEditComponent } from './components/invoice-edit/invoice-edit.component';

import { AdminComponent } from './components/admin/admin.component';
import { ToolbarModule } from 'primeng/toolbar';
import { AccordionModule } from 'primeng/accordion';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { CheckboxModule } from 'primeng/checkbox';
import { ListboxModule } from 'primeng/listbox';
import { DropdownModule } from 'primeng/dropdown';
import { FileUploadModule } from 'primeng/fileupload';
import { CardModule } from 'primeng/card';
import { MultiSelectModule } from 'primeng/multiselect';
import { CalendarModule } from 'primeng/calendar';
import { DialogModule } from 'primeng/dialog';

import { BsDropdownModule } from 'ngx-bootstrap/dropdown'

import { AuthenticationServiceService } from '../services/authentication-service.service';
import { RailFleetService } from '../services/rail-fleet.service';
import { DragDropDirective } from '../services/drag-drop.directive';
import { Global } from './global';
import { UserManagementComponent } from './components/user-management/user-management/user-management.component';
import { UserFormComponent } from './components/user-management/user-form/user-form.component';
import { DialogpopupComponent } from './common/dialogpopup/dialogpopup.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatMenuModule } from '@angular/material/menu';
import { RailcarUrlsComponent } from './components/railcar-urls/railcar-urls.component';
import { RailcarLinksComponent } from './components/railcar-links/railcar-links.component';
import { AllspecsComponent } from './components/allspecs/allspecs.component';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RailcarurlsMasterComponent } from './railcarurls-master/railcarurls-master/railcarurls-master.component';
import { RailcarurlsFormComponent } from './railcarurls-master/railcarurls-form/railcarurls-form.component';
import {MatRadioModule} from '@angular/material/radio';
import { TooltipModule } from "primeng/tooltip";
import { DocumentsLinkComponent } from './documents-link/documents-link.component';
import { DocumenturlsMasterComponent } from './documenturls-master/documenturls-master/documenturls-master.component';
import { DocumenturlsFormComponent } from './documenturls-master/documenturls-form/documenturls-form.component';
import { OverviewComponent } from './components/overview/overview.component';
import { RequestAccessComponent } from './common/request-access/request-access.component';
import { DialogrequestComponent } from './common/dialogrequest/dialogrequest.component';
import { AdminNotificationComponent } from './components/admin-notification/admin-notification.component';
import {PanelModule} from 'primeng/panel';
import { InvoiceTotalComponent } from './components/invoice-total/invoice-total.component';
import { HistoricalMaintenanceComponent } from './components/historical-maintenance/historical-maintenance.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    LoginTestComponent,
    LoginComponent,
    UserScreenComponent,
    UserPopupComponent,
    DragDropDirective,
    LogoutComponent,
    RailCarInformationComponent,
    RailCarInfoSelectionComponent,
    RailCarInfoEditComponent,
    RailCarModifyComponent,
    InvoiceInformationComponent,
    InvoiceSelectionComponent,
    InvoiceEditComponent,
    ViewAttachmentsComponent,
    AdminComponent,
    UserManagementComponent,
    UserFormComponent,
    DialogpopupComponent,
    RailcarUrlsComponent,
    RailcarLinksComponent,
    AllspecsComponent,
    RailcarurlsMasterComponent,
    RailcarurlsFormComponent,
    DocumentsLinkComponent,
    DocumenturlsMasterComponent,
    DocumenturlsFormComponent,
    OverviewComponent,
    RequestAccessComponent,
    DialogrequestComponent,
    AdminNotificationComponent,
    InvoiceTotalComponent,
    HistoricalMaintenanceComponent
  ],

  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    RouterModule,
    TableModule,
    TooltipModule,
    ToolbarModule,
    AccordionModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
      closeButton: true
    }),
    ToastrModule,
    NgxSpinnerModule,
    TabViewModule,
    DynamicDialogModule,
    ButtonModule,
    InputTextModule,
    CheckboxModule,
    AutoCompleteModule,
    ListboxModule,
    DropdownModule,
    PanelModule,
    FileUploadModule,
    CardModule,
    BsDropdownModule.forRoot(),
    MultiSelectModule,
    CalendarModule,
    DialogModule,
    ConfirmDialogModule,
    RadioButtonModule,
    MatTabsModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatFormFieldModule,
    MatInputModule,
    MatTableModule,
    MatButtonModule,
    MatPaginatorModule,
    MatSortModule,
    MatDialogModule,
    MatRadioModule,
    MatSnackBarModule,
    MatSelectModule,
    MatDatepickerModule,
    MatStepperModule,
    MatTooltipModule,
    MatMenuModule,
    MatCheckboxModule,
    MatNativeDateModule,
  ],
  providers: [    
    { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true },
    // { provide: HTTP_INTERCEPTORS, useClass: httpInterceptor, multi: true },
  
    CookieService,
    Global,
    AuthenticationServiceService,
    RailFleetService,
    MessageService,
    ConfirmationService
  ],
  bootstrap: [AppComponent],

  entryComponents: [
    
    UserPopupComponent,
    UserFormComponent,
    DialogpopupComponent,
    AllspecsComponent,
    RailcarurlsFormComponent,
    DocumenturlsFormComponent,
    RequestAccessComponent,
    DialogrequestComponent
    
  ],

  schemas: [CUSTOM_ELEMENTS_SCHEMA]

})
export class AppModule { }
