import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { RailFleetService } from 'src/services/rail-fleet.service';

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.css']
})
export class UserFormComponent implements OnInit {

  newUser: boolean = true;
  region = 1;
  userForm: FormGroup;
  bus:any;
  roles:any;
  // @Input() userAdmin;
  Admin=false;
  selected=[];
  record=[];
  constructor(@Inject(MAT_DIALOG_DATA) private Data: any, private dialogRef: MatDialogRef<UserFormComponent>, private _service: RailFleetService, private _toast: ToastrService) {

  }

  ngOnInit(): void {
    this.userForm = new FormGroup({      
      basfUserID: new FormControl('', [Validators.required]),
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      roleID: new FormControl('', [Validators.required]),
      Email: new FormControl('', [Validators.required, Validators.email]),
      buID: new FormControl(''),
      
    });

    if(this.Data.isModify && this.Data != undefined)
    {
      if(this.Data.buID.length>0)
      {
        this.Data.buID.forEach(element => {
          this.record.push(element.buID);
        });
      }     
    }
    
    if (this.Data.isModify && this.Data != undefined) 
    {      
      this.userForm.controls.basfUserID.setValue(this.Data.basfUserID);
      this.userForm.controls.firstName.setValue(this.Data.firstName);
      this.userForm.controls.lastName.setValue(this.Data.lastName);
      this.userForm.controls.roleID.setValue(this.Data.roleID);
      this.userForm.controls.Email.setValue(this.Data.email);
      this.userForm.controls.buID.setValue(this.record);
    }
    
    this.GetAllBus();
    this.GetAllRoles()
    this.SetAdmin();
  }
  SetAdmin() {
    if(this.Data.roleID==1)
     {
        this.Admin=true;
     }
  }
  GetAllBus()
  {
    this._service.GetAllBus().subscribe((data:any[])=>{
      
      this.bus=data;
    });
  }
  GetAllRoles()
  {
    this._service.GetAllRoles().subscribe((data:any[])=>{
      this.roles=data;
    });
  }
  doSomething(value:number)
  {
    if(value==1)
    {
       this.Admin=true;
    }else
    {
      this.Admin=false;
    }
    
  }
  public hasError = (controlName: string, errorName: string) => {
    return this.userForm.controls[controlName].hasError(errorName);
  }
  onSend() {
    if (this.userForm.valid && this.Data.isModify) {
      const acPayLoad = {
        userID: this.Data.userID ? this.Data.userID : 0,
        basfUserID: this.userForm.controls.basfUserID.value,
        firstName: this.userForm.controls.firstName.value,
        lastName: this.userForm.controls.lastName.value,
        roleID: this.userForm.controls.roleID.value,
        Email: this.userForm.controls.Email.value,
        buID: this.userForm.controls.buID.value,
        actionBy: this.userForm.controls.basfUserID.value,
        action: "Update"
      }
      if(acPayLoad.roleID==1)
      {
         acPayLoad.buID="0";
         this._service.updateUser_new(acPayLoad).subscribe(res => { this._toast.success("User Successfully Updated") });
         this.dialogRef.close();
        }
        acPayLoad.buID=acPayLoad.buID.join(',');     
      this._service.updateUser_new(acPayLoad).subscribe(res => { this._toast.success("User Successfully Updated") });
    }  
      this.dialogRef.close();
  }

}
