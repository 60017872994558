import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})

export class AppComponent {
  enableBg = true;
  userAccess = false;
  userAdmin = false;

  unAuthorised = false;
  constructor(
    private router: Router
  ) {
    router.events.pipe(
      filter(event => event instanceof NavigationEnd)  
    ).subscribe((event: NavigationEnd) => {
      if(event.url == '/login-test' || event.url == '/login' || event.url == '/logout') {
        this.userAccess = false;
        sessionStorage.setItem('governanceEvaluationAccess', "false");
        sessionStorage.setItem('role','');
      } else {
        this.userAccess = true;
        let role = sessionStorage.getItem('role');
        if(role != null && role != undefined && role != '' ) {
          if(role.includes('Admin')) {
            this.userAdmin = true;
          } else {
            this.userAdmin = false;
          }
        } else {
          this.userAdmin = false;
        }
      }
    });
  }
  title = 'app';
}
