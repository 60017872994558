import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { forkJoin, Observable, Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class RailFleetService {
  private nav_menuSubject = new Subject<boolean>();
  constructor(private _http: HttpClient) { }

  setNavmenustatus(status: boolean) {
    this.nav_menuSubject.next(status);

  }

  getNavmenustatus(): Observable<boolean> {
    return this.nav_menuSubject.asObservable();
  }

  getallrailfleetData(data) {
    //return this._http.get(`${environment.getallrailfleetData}${data}`).pipe(map((response) => response));
    return this._http.post(`${environment.getallrailfleetData}`, JSON.parse(JSON.stringify(data))).pipe(map((response) => response));
  }

  getAllPermittedcommodities() {
    return this._http.get(`${environment.getAllPermittedcommodities}`).pipe(map((response) => response));
  }
  GetFleetVsNextRegYr() {
    return this._http.get(`${environment.GetFleetVsNextRegYr}`).pipe(map((response) => response));
  }
  GetFleetVsExpiration() {
    return this._http.get(`${environment.GetFleetVsExpiration}`).pipe(map((response) => response));
  }
  GetFleetVsOwner() {
    return this._http.get(`${environment.GetFleetVsOwner}`).pipe(map((response) => response));
  }

  getAllPackingGroup() {
    return this._http.get(`${environment.getAllPackingGroup}`).pipe(map((response) => response));
  }

  getAllHazardClass() {
    return this._http.get(`${environment.getAllHazardClass}`).pipe(map((response) => response));
  }

  getAllCarType() {
    return this._http.get(`${environment.getAllCarType}`).pipe(map((response) => response));
  }

  getAllDOTAARClass() {
    return this._http.get(`${environment.getAllDOTAARClass}`).pipe(map((response) => response));
  }

  getAllCarBuilder() {
    return this._http.get(`${environment.getAllCarBuilder}`).pipe(map((response) => response));
  }
  getAllRailCarUrls() {
    return this._http.get(`${environment.getAllRailCarUrls}`).pipe(map((response) => response));
  }
  getAllDocumentsUrls() {
    return this._http.get(`${environment.getAllDocumentsUrls}`).pipe(map((response) => response));
  }

  getRailMasterData() {
    return forkJoin({
      permittedCommodities: this.getAllPermittedcommodities(),
      packagingGroups: this.getAllPackingGroup(),
      hazardClasses: this.getAllHazardClass(),
      carTypes: this.getAllCarType(),
      dot_aar_classes: this.getAllDOTAARClass(),
      carBuilders: this.getAllCarBuilder()

    });
  }

  updateRailFleetData(railFleetData) {
    return this._http.post(`${environment.updateRailFleetData}`, JSON.parse(JSON.stringify(railFleetData))).pipe(map((response) => response));
  }
  updaterailBulkfleetdetails(railFleetData) {
    return this._http.post(`${environment.updaterailBulkfleetdetails}`, JSON.parse(JSON.stringify(railFleetData))).pipe(map((response) => response));
  }

  updatePermittedCmmodities(pcData) {
    return this._http.post(`${environment.updatePermittedcommodities}`, JSON.parse(JSON.stringify(pcData))).pipe(map((response) => response));
  }

  updatePackingGroup(pgData) {
    return this._http.post(`${environment.updatePackinggroup}`, JSON.parse(JSON.stringify(pgData))).pipe(map((response) => response));
  }

  updateHazardclass(hcData) {
    return this._http.post(`${environment.updatehazardclass}`, JSON.parse(JSON.stringify(hcData))).pipe(map((response) => response));
  }

  updateCarBuilder(cbData) {
    return this._http.post(`${environment.updatecarbuilder}`, JSON.parse(JSON.stringify(cbData))).pipe(map((response) => response));
  }

  getDecryptedUserDetails(data) {
    return this._http.post(environment.decryptUserDetails, data).pipe(map((response) => response));
  }

  getSAMLService() {
    return this._http.get(environment.getSAMLURL).pipe(map((response) => response));
  }

  getUserInfo(data) {
    return this._http.get(`${environment.getUserInfo}${data}`).pipe(map((response) => response));
  }

  getUsers(data) {
    return this._http.get(`${environment.getUsers}${data}`).pipe(map((response) => response));
  }
  getUsers_new() {
    return this._http.get(environment.getUsers_new + 'All').pipe(map((response) => response));
  }
  getAllSpecs(data) {
    return this._http.get(`${environment.getAllSpecs}${data}`).pipe(map((response) => response));
  }
  emailAllSpecs(data) {
    return this._http.get(`${environment.emailAllSpecs}${data}`).pipe(map((response) => response));
  }
  updateUser_new(Data) {
    return this._http.post(`${environment.updateUser_new}`, JSON.parse(JSON.stringify(Data))).pipe(map((response) => response));
  }
  mailRequest(Data) {
    return this._http.post(`${environment.mailRequest}`, JSON.parse(JSON.stringify(Data))).pipe(map((response) => response));
  }
  GetAllBus() {
    return this._http.get(environment.getallbus + 'All').pipe(map((response) => response));
  }
  GetAllRoles() {
    return this._http.get(environment.getallroles + 0).pipe(map((response) => response));
  }

  getAttachmentsURL() {
    return this._http.get(environment.getAttachmentsUrl).pipe(map((response) => response));
  }

  updateAttachmentUrl(aData) {
    return this._http.post(`${environment.updateAttachementsUrl}`, JSON.parse(JSON.stringify(aData))).pipe(map((response) => response));
  }
  updateRailCarUrls(aData) {
    return this._http.post(`${environment.updateRailCarUrls}`, JSON.parse(JSON.stringify(aData))).pipe(map((response) => response));
  }
  updateDocumentsUrls(aData) {
    return this._http.post(`${environment.updateDocumentsUrls}`, JSON.parse(JSON.stringify(aData))).pipe(map((response) => response));
  }
  getAllNotifications() {
    return this._http.get(environment.GetAllNotifications).pipe(map((response) => response));
  }
  getCurrentNotifications() {
    return this._http.get(environment.GetCurrentNotifications).pipe(map((response) => response));
  }
  addNotification(data) {
    return this._http.post(environment.CreateNotifications, data).pipe(map((response) => response));
  }


  getRailfleetInvoiceData(data) {
    return this._http.get(`${environment.getRailfleetInvoiceData}${data}`).pipe(map((response) => response));
  }
  
  updateBulkInvoiceDetails(invoiceData) {
    return this._http.post(`${environment.updateBulkInvoiceDetails}`, JSON.parse(JSON.stringify(invoiceData))).pipe(map((response) => response));
  }

  reviewInvoiceDetails(invoiceData) {
    return this._http.post(`${environment.reviewInvoiceDetails}`, JSON.parse(JSON.stringify(invoiceData))).pipe(map((response) => response));
  }
  updateMvdAccountUsers(data) {
    return this._http.get(`${environment.updateMvdAccountUsers}${data}`).pipe(map((response) => response));
  }

  getInvoiceHistoryData(PayLoad) {
    return this._http.post(`${environment.getInvoiceHistoricalMaintenance}`, JSON.parse(JSON.stringify(PayLoad))).pipe(map((response) => response));
  }

  syncInvoiceHistoryData(PayLoad) {
    return this._http.post(`${environment.syncInvoiceHistoricalMaintenance}`, JSON.parse(JSON.stringify(PayLoad))).pipe(map((response) => response));
  }
}
