import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SelectItem } from 'primeng/api';
import { RailFleetService } from 'src/services/rail-fleet.service';

@Component({
  selector: 'app-documents-link',
  templateUrl: './documents-link.component.html',
  styleUrls: ['./documents-link.component.css']
})
export class DocumentsLinkComponent implements OnInit {

documentsURLs: any;

    cols: any[];
    carUrls: SelectItem[];
    apiResp:any;
    routerLinkVariable:any;

    constructor(private _Service: RailFleetService,private router: Router) {
      
     }

    ngOnInit() {
        this._Service.getAllDocumentsUrls().subscribe(response => {
          this.documentsURLs=response;
        });

        this.cols = [
            { field: 'descriptionName', header: 'Description Name' },
            { field: 'url', header: 'URLS' }           
        ];
        this.routerLinkVariable
       
    }
    onGoToPage2(data:any)
    {
      window.open(data, '_blank');
    }
    backToGrid()
    {
      this.router.navigate(['/rail-car']);
    }

}
