import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { RailFleetService } from 'src/services/rail-fleet.service';

@Component({
  selector: 'app-allspecs',
  templateUrl: './allspecs.component.html',
  styleUrls: ['./allspecs.component.css']
})
export class AllspecsComponent implements OnInit  {
  dataSource = new MatTableDataSource();
  apires:any;
  datasets:any;
  titile:any;
  constructor(@Inject(MAT_DIALOG_DATA) private Data: any, private dataService:RailFleetService) {
    
  }
  ngOnInit(): void {
    this.getAllSpecs(this.Data);
  }
  getAllSpecs(Data)
  {
    this.titile=Data;
     
    this.dataService.getAllSpecs(Data).subscribe((res :any)=> {
      this.apires=res;
      this.datasets=this.apires;
    })
  }
  sendEmail() {
    this.dataService.emailAllSpecs(this.Data).subscribe((res: any) => {
      this.apires = res;
      const blob = this.b64toBlob(this.apires, 'application/pdf');
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl,'_blank');
    })

  }
 b64toBlob = (b64Data, contentType='', sliceSize=512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
  
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
  
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
  
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
  
    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
  }
  downloadPdf() {
    var mywindow = window.open('', 'PRINT', 'height=400,width=600');
    mywindow.document.write('<html><head><title></title><style>@page {size: A4;}body{font-family:Arial;font-size:12px;}table{width:100%;}table span{display:block;width:100%;background:#004890;color:#fff;line-hieght:25px;font-size:14px;}table td{vertical-align:top !important;}#tblnxt{ page-break-inside: avoid }</style>');
    mywindow.document.write('</head><body >');
    mywindow.document.write('');
    var strEv = "<table>";
    strEv = strEv + "<tr><td colspan='2'><span><h1>"+this.titile+"</h1></span></td></tr>";
    
    if (this.datasets[0].air_Brake_Test_Due  != undefined)
    {
      strEv = strEv + "<tr><td  style='width:35%'><strong>Air_Brake_Test_Due:</strong></td><td>" + this.datasets[0].air_Brake_Test_Due + "</td></tr>";
    }else
    {
      strEv = strEv + "<tr><td  style='width:35%'><strong>Air_Brake_Test_Due:</strong></td><td>" +''+ "</td></tr>";
    }
    
    if (this.datasets[0].auxiliary_BOV_Size  != undefined)
    {
      strEv = strEv + "<tr><td><strong>Auxiliary_BOV_Size:</strong></td><td>" + this.datasets[0].auxiliary_BOV_Size+ "</td></tr>";
    }else
    {
      strEv = strEv + "<tr><td><strong>Auxiliary_BOV_Size:</strong></td><td>" +''+ "</td></tr>";
    }
    if (this.datasets[0].auxiliary_BOV_Type  != undefined)
    {
      strEv = strEv + "<tr><td><strong>Auxiliary_BOV_Type:</strong></td><td>" + this.datasets[0].auxiliary_BOV_Type+ "</td></tr>";
    }else
    {
      strEv = strEv + "<tr><td><strong>Auxiliary_BOV_Type:</strong></td><td>" +''+ "</td></tr>";
    }
    if (this.datasets[0].bottom_Outlet_Valve_Size  != undefined)
    {
      strEv = strEv + "<tr><td><strong>Bottom_Outlet_Valve_Size:</strong></td><td>" + this.datasets[0].bottom_Outlet_Valve_Size + "</td></tr>";
    }else
    {
      strEv = strEv + "<tr><td><strong>Bottom_Outlet_Valve_Size:</strong></td><td>" +''+ "</td></tr>";
    }
    if (this.datasets[0].bottom_Outlet_Valve_Type  != undefined)
    {
      strEv = strEv + "<tr><td><strong>Bottom_Outlet_Valve_Type:</strong></td><td>" + this.datasets[0].bottom_Outlet_Valve_Type + "</td></tr>";
    }else
    {
      strEv = strEv + "<tr><td><strong>Bottom_Outlet_Valve_Type:</strong></td><td>" +''+ "</td></tr>";
    }
    if (this.datasets[0].centerband_Materials  != undefined)
    {
      strEv = strEv + "<tr><td><strong>Centerband_Materials:</strong></td><td>" + this.datasets[0].centerband_Materials + "</td></tr>";
    }else
    {
      strEv = strEv + "<tr><td><strong>Centerband_Materials:</strong></td><td>" +''+ "</td></tr>";
    }
    if (this.datasets[0].certification_Date   != undefined)
    {
      strEv = strEv + "<tr><td><strong>Certification_Date:</strong></td><td>" + this.datasets[0].certification_Date + "</td></tr>";
    }else
    {
      strEv = strEv + "<tr><td><strong>Certification_Date:</strong></td><td>" +''+ "</td></tr>";
    }
    if (this.datasets[0].certification_Type    != undefined)
    {
      strEv = strEv + "<tr><td><strong>Certification_Type:</strong></td><td>" + this.datasets[0].certification_Type + "</td></tr>";
    }else
    {
      strEv = strEv + "<tr><td><strong>Certification_Type:</strong></td><td>" +''+ "</td></tr>";
    }   
    if (this.datasets[0].coil_Type  != undefined)
    {
      strEv = strEv + "<tr><td><strong>Coil_Type:</strong></td><td>" + this.datasets[0].coil_Type+ "</td></tr>";
    }else
    {
      strEv = strEv + "<tr><td><strong>Coil_Type:</strong></td><td>" +''+ "</td></tr>";
    }
    if (this.datasets[0].coils != undefined)
    {
      strEv = strEv + "<tr><td><strong>Coils:</strong></td><td>" + this.datasets[0].coils + "</td></tr>";
    }else
    {
      strEv = strEv + "<tr><td><strong>Coils:</strong></td><td>" +''+ "</td></tr>";
    }
    if (this.datasets[0].contract_FMV!= undefined)
    {
      strEv = strEv + "<tr><td><strong>Contract_FMV:</strong></td><td>" + this.datasets[0].contract_FMV + "</td></tr>";
    }else
    {
      strEv = strEv + "<tr><td><strong>Contract_FMV:</strong></td><td>" +''+ "</td></tr>";
    }
    if (this.datasets[0].fleet_Obj_Height!= undefined)
    {
      strEv = strEv + "<tr><td><strong>Fleet_Obj_Height:</strong></td><td>" + this.datasets[0].fleet_Obj_Height + "</td></tr>";
    }else
    {
      strEv = strEv + "<tr><td><strong>Fleet_Obj_Height:</strong></td><td>" +''+ "</td></tr>";
    }
    if (this.datasets[0].fleet_Obj_Height_Unit!= undefined)
    {
      strEv = strEv + "<tr><td><strong>Fleet_Obj_Height_Unit:</strong></td><td>" + this.datasets[0].fleet_Obj_Height_Unit + "</td></tr>";
    }else
    {
      strEv = strEv + "<tr><td><strong>Fleet_Obj_Height_Unit:</strong></td><td>" +''+ "</td></tr>";
    }
    if (this.datasets[0].fleet_Obj_Width != undefined)
    {
      strEv = strEv + "<tr><td><strong>Fleet_Obj_Width:</strong></td><td>" + this.datasets[0].fleet_Obj_Width + "</td></tr>";
    }else
    {
      strEv = strEv + "<tr><td><strong>Fleet_Obj_Width:</strong></td><td>" +''+ "</td></tr>";
    }
    if (this.datasets[0].fleet_Obj_Width_Unit != undefined)
    {
      strEv = strEv + "<tr><td><strong>Fleet_Obj_Width_Unit:</strong></td><td>" + this.datasets[0].fleet_Obj_Width_Unit + "</td></tr>";
    }else
    {
      strEv = strEv + "<tr><td><strong>Fleet_Obj_Width_Unit:</strong></td><td>" +''+ "</td></tr>";
    }
    if (this.datasets[0].fleet_Object_Type != undefined)
    {
      strEv = strEv + "<tr><td><strong>Fleet_Object_Type:</strong></td><td>" + this.datasets[0].fleet_Object_Type + "</td></tr>";
    }else
    {
      strEv = strEv + "<tr><td><strong>Fleet_Object_Type:</strong></td><td>" +''+ "</td></tr>";
    }
    if (this.datasets[0].gasket_Type_Flat_Surf != undefined)
    {
      strEv = strEv + "<tr><td><strong>Gasket_Type_Flat_Surf:</strong></td><td>" + this.datasets[0].gasket_Type_Flat_Surf + "</td></tr>";
    }else
    {
      strEv = strEv + "<tr><td><strong>Gasket_Type_Flat_Surf:</strong></td><td>" +''+ "</td></tr>";
    }
    if (this.datasets[0].hatch_Size != undefined)
    {
      strEv = strEv + "<tr><td><strong>Hatch_Size:</strong></td><td>" + this.datasets[0].hatch_Size + "</td></tr>";
    }else
    {
      strEv = strEv + "<tr><td><strong>Hatch_Size:</strong></td><td>" +''+ "</td></tr>";
    }
    if (this.datasets[0].hatch_Type != undefined)
    {
      strEv = strEv + "<tr><td><strong>Hatch_Type:</strong></td><td>" + this.datasets[0].hatch_Type + "</td></tr>";
    }else
    {
      strEv = strEv + "<tr><td><strong>Hatch_Type:</strong></td><td>" +''+ "</td></tr>";
    }
    if (this.datasets[0].hatch_Vent_Cover != undefined)
    {
      strEv = strEv + "<tr><td><strong>Hatch_Vent_Cover:</strong></td><td>" + this.datasets[0].hatch_Vent_Cover + "</td></tr>";
    }else
    {
      strEv = strEv + "<tr><td><strong>Hatch_Vent_Cover:</strong></td><td>" +''+ "</td></tr>";
    }
    if (this.datasets[0].insulation_Thickness  != undefined)
    {
      strEv = strEv + "<tr><td><strong>Insulation_Thickness:</strong></td><td>" + this.datasets[0].insulation_Thickness + "</td></tr>";
    }else
    {
      strEv = strEv + "<tr><td><strong>Insulation_Thickness:</strong></td><td>" +''+ "</td></tr>";
    }
    if ( this.datasets[0].insulation_Type   != undefined)
    {
      strEv = strEv + "<tr><td><strong>Insulation_Type:</strong></td><td>" + this.datasets[0].insulation_Type + "</td></tr>";
    }else
    {
      strEv = strEv + "<tr><td><strong>Insulation_Type:</strong></td><td>" +''+ "</td></tr>";
    }
    if ( this.datasets[0].last_Fleet_Sizing   != undefined)
    {
      strEv = strEv + "<tr><td><strong>Last_Fleet_Sizing:</strong></td><td>" + this.datasets[0].last_Fleet_Sizing + "</td></tr>";
    }else
    {
      strEv = strEv + "<tr><td><strong>Last_Fleet_Sizing:</strong></td><td>" +''+ "</td></tr>";
    }
    if (  this.datasets[0].last_Paint   != undefined)
    {
      strEv = strEv + "<tr><td><strong>Last_Paint:</strong></td><td>" + this.datasets[0].last_Paint + "</td></tr>";
    }else
    {
      strEv = strEv + "<tr><td><strong>Last_Paint:</strong></td><td>" +''+ "</td></tr>";
    }
    if (  this.datasets[0].lining_Last_Date    != undefined)
    {
      strEv = strEv + "<tr><td><strong>Lining_Last_Date:</strong></td><td>" + this.datasets[0].lining_Last_Date + "</td></tr>";
    }else
    {
      strEv = strEv + "<tr><td><strong>Lining_Last_Date:</strong></td><td>" +''+ "</td></tr>";
    }
    if ( this.datasets[0].lining_Product     != undefined)
    {
      strEv = strEv + "<tr><td><strong>Lining_Product:</strong></td><td>" + this.datasets[0].lining_Product + "</td></tr>";
    }else
    {
      strEv = strEv + "<tr><td><strong>Lining_Product:</strong></td><td>" +''+ "</td></tr>";
    }
    if (this.datasets[0].load_Volume != undefined)
    {
      strEv = strEv + "<tr><td><strong>Load_Volume:</strong></td><td>" + this.datasets[0].load_Volume + "</td></tr>";
    }else
    {
      strEv = strEv + "<tr><td><strong>Load_Volume:</strong></td><td>" +''+ "</td></tr>";
    }
    if ( this.datasets[0].max_Load_Weight != undefined)
    {
      strEv = strEv + "<tr><td><strong>Max_Load_Weight:</strong></td><td>" + this.datasets[0].max_Load_Weight + "</td></tr>";
    }else
    {
      strEv = strEv + "<tr><td><strong>Max_Load_Weight:</strong></td><td>" +''+ "</td></tr>";
    }
    if ( this.datasets[0].max_Load_weight_Unit != undefined)
    {
      strEv = strEv + "<tr><td><strong>Max_Load_weight_Unit:</strong></td><td>" + this.datasets[0].max_Load_weight_Unit + "</td></tr>";
    }else
    {
      strEv = strEv + "<tr><td><strong>Max_Load_weight_Unit:</strong></td><td>" +''+ "</td></tr>";
    }
    if ( this.datasets[0].mileage_Credit_Rate  != undefined)
    {
      strEv = strEv + "<tr><td><strong>Mileage_Credit_Rate:</strong></td><td>" + this.datasets[0].mileage_Credit_Rate + "</td></tr>";
    }else
    {
      strEv = strEv + "<tr><td><strong>Mileage_Credit_Rate:</strong></td><td>" + + "</td></tr>";
    }
    if (  this.datasets[0].object_Length   != undefined)
    {
      strEv = strEv + "<tr><td><strong>Object_Length:</strong></td><td>" + this.datasets[0].object_Length + "</td></tr>";
    }else
    {
      strEv = strEv + "<tr><td><strong>Object_Length:</strong></td><td>" +''+ "</td></tr>";
    }
    if ( this.datasets[0].object_Length_Unit   != undefined)
    {
      strEv = strEv + "<tr><td><strong>Object_Length_Unit:</strong></td><td>" + this.datasets[0].object_Length_Unit + "</td></tr>";
    }else
    {
      strEv = strEv + "<tr><td><strong>Object_Length_Unit:</strong></td><td>" +''+ "</td></tr>";
    }
    if (  this.datasets[0].outage_Table   != undefined)
    {
      strEv = strEv + "<tr><td><strong>Outage_Table:</strong></td><td>" + this.datasets[0].outage_Table + "</td></tr>";
    }else
    {
      strEv = strEv + "<tr><td><strong>Outage_Table:</strong></td><td>" +''+ "</td></tr>";
    }
    if ( this.datasets[0].outlet_Gate_1_Size  != undefined)
    {
      strEv = strEv + "<tr><td><strong>Outlet_Gate_1_Size:</strong></td><td>" + this.datasets[0].outlet_Gate_1_Size + "</td></tr>";
    }else
    {
      strEv = strEv + "<tr><td><strong>Outlet_Gate_1_Size:</strong></td><td>" +''+ "</td></tr>";
    }
    if ( this.datasets[0].outlet_Gate_1_Type   != undefined)
    {
      strEv = strEv + "<tr><td><strong>Outlet_Gate_1_Type:</strong></td><td>" + this.datasets[0].outlet_Gate_1_Type + "</td></tr>";
    }else
    {
      strEv = strEv + "<tr><td><strong>Outlet_Gate_1_Type:</strong></td><td>" +''+ "</td></tr>";
    }
    if (  this.datasets[0].outlet_Gate_2_Size   != undefined)
    {
      strEv = strEv + "<tr><td><strong>Outlet_Gate_2_Size:</strong></td><td>" + this.datasets[0].outlet_Gate_2_Size + "</td></tr>";
    }else
    {
      strEv = strEv + "<tr><td><strong>Outlet_Gate_2_Size:</strong></td><td>" +''+ "</td></tr>";
    }
    if ( this.datasets[0].outlet_Gate_2_Type   != undefined)
    {
      strEv = strEv + "<tr><td><strong>Outlet_Gate_2_Type:</strong></td><td>" + this.datasets[0].outlet_Gate_2_Type + "</td></tr>";
    }else
    {
      strEv = strEv + "<tr><td><strong>Outlet_Gate_2_Type:</strong></td><td>" +''+ "</td></tr>";
    }
    if ( this.datasets[0].outlet_Gate_3_Size   != undefined)
    {
      strEv = strEv + "<tr><td><strong>Outlet_Gate_3_Size:</strong></td><td>" + this.datasets[0].outlet_Gate_3_Size + "</td></tr>";
    }else
    {
      strEv = strEv + "<tr><td><strong>Outlet_Gate_3_Size:</strong></td><td>" +''+ "</td></tr>";
    }
    if ( this.datasets[0].outlet_Gate_3_Type != undefined)
    {
      strEv = strEv + "<tr><td><strong>Outlet_Gate_3_Type:</strong></td><td>" + this.datasets[0].outlet_Gate_3_Type + "</td></tr>";
    }else
    {
      strEv = strEv + "<tr><td><strong>Outlet_Gate_3_Type:</strong></td><td>" +''+ "</td></tr>";
    }
    if (  this.datasets[0].outlet_Gate_4_Type != undefined)
    {
      strEv = strEv + "<tr><td><strong>Outlet_Gate_4_Type:</strong></td><td>" + this.datasets[0].outlet_Gate_4_Type + "</td></tr>";
    }else
    {
      strEv = strEv + "<tr><td><strong>Outlet_Gate_4_Type:</strong></td><td>" +''+ "</td></tr>";
    }
    if ( this.datasets[0].outlet_Gate_4_Type1  != undefined)
    {
      strEv = strEv + "<tr><td><strong>Outlet_Gate_4_Type1:</strong></td><td>" + this.datasets[0].outlet_Gate_4_Type1 + "</td></tr>";
    }else
    {
      strEv = strEv + "<tr><td><strong>Outlet_Gate_4_Type1:</strong></td><td>" +''+ "</td></tr>";
    }
    if (  this.datasets[0].paint_Product  != undefined)
    {
      strEv = strEv + "<tr><td><strong>Paint_Product:</strong></td><td>" + this.datasets[0].paint_Product + "</td></tr>";
    }else
    {
      strEv = strEv + "<tr><td><strong>Paint_Product:</strong></td><td>" +''+ "</td></tr>";
    }
    if (this.datasets[0].rule_88_Due!= undefined)
    {
      strEv = strEv + "<tr><td><strong>Rule_88_Due:</strong></td><td>" + this.datasets[0].rule_88_Due + "</td></tr>";
    }else
    {
      strEv = strEv + "<tr><td><strong>Rule_88_Due:</strong></td><td>" +''+ "</td></tr>";
    }
    if ( this.datasets[0].rupture_Disc != undefined)
    {
      strEv = strEv + "<tr><td><strong>Rupture_Disc:</strong></td><td>" + this.datasets[0].rupture_Disc + "</td></tr>";
    }else
    {
      strEv = strEv + "<tr><td><strong>Rupture_Disc:</strong></td><td>" +''+ "</td></tr>";
    }
    if ( this.datasets[0].sS_3_Due != undefined)
    {
      strEv = strEv + "<tr><td><strong>SS-3_Due:</strong></td><td>" + this.datasets[0].sS_3_Due + "</td></tr>";
    }else
    {
      strEv = strEv + "<tr><td><strong>SS-3_Due:</strong></td><td>" +''+ "</td></tr>";
    }
    if (  this.datasets[0].safety_Valve_Size  != undefined)
    {
      strEv = strEv + "<tr><td><strong>Safety_Valve_Size:</strong></td><td>" + this.datasets[0].safety_Valve_Size + "</td></tr>";
    }else
    {
      strEv = strEv + "<tr><td><strong>Safety_Valve_Size:</strong></td><td>" +''+ "</td></tr>";
    }
    if ( this.datasets[0].safety_Valve_Test_Due  != undefined)
    {
      strEv = strEv + "<tr><td><strong>Safety_Valve_Test_Due:</strong></td><td>" + this.datasets[0].safety_Valve_Test_Due + "</td></tr>";
    }else
    {
      strEv = strEv + "<tr><td><strong>Safety_Valve_Test_Due:</strong></td><td>" +''+ "</td></tr>";
    }
    if ( this.datasets[0].safety_Valve_Type != undefined)
    {
      strEv = strEv + "<tr><td><strong>Safety_Valve_Type:</strong></td><td>" + this.datasets[0].safety_Valve_Type + "</td></tr>";
    }else
    {
      strEv = strEv + "<tr><td><strong>Safety_Valve_Type:</strong></td><td>" +''+ "</td></tr>";
    }
    if ( this.datasets[0].skid_Protection  != undefined)
    {
      strEv = strEv + "<tr><td><strong>Skid_Protection:</strong></td><td>" + this.datasets[0].skid_Protection + "</td></tr>";
    }else
    {
      strEv = strEv + "<tr><td><strong>Skid_Protection:</strong></td><td>" +''+ "</td></tr>";
    }
    if ( this.datasets[0].status_Code!= undefined)
    {
      strEv = strEv + "<tr><td><strong>Status_Code:</strong></td><td>" + this.datasets[0].status_Code+ "</td></tr>";
    }else
    {
      strEv = strEv + "<tr><td><strong>Status_Code:</strong></td><td>" +''+ "</td></tr>";
    }
    if ( this.datasets[0].status_Comment != undefined)
    {
      strEv = strEv + "<tr><td><strong>Status_Comment:</strong></td><td>" + this.datasets[0].status_Comment + "</td></tr>";
    }else
    {
      strEv = strEv + "<tr><td><strong>Status_Comment:</strong></td><td>" +''+ "</td></tr>";
    }
    if (  this.datasets[0].status_Date != undefined)
    {
      strEv = strEv + "<tr><td><strong>Status_Date:</strong></td><td>" + this.datasets[0].status_Date + "</td></tr>";
    }else
    {
      strEv = strEv + "<tr><td><strong>Status_Date:</strong></td><td>" +''+ "</td></tr>";
    }
    if ( this.datasets[0].tank_Qualified!= undefined)
    {
      strEv = strEv + "<tr><td><strong>Tank_Qualified:</strong></td><td>" + this.datasets[0].tank_Qualified+ "</td></tr>";
    }else
    {
      strEv = strEv + "<tr><td><strong>Tank_Qualified:</strong></td><td>" +''+ "</td></tr>";
    }
    if ( this.datasets[0].tank_Test_Due != undefined)
    {
      strEv = strEv + "<tr><td><strong>Tank_Test_Due:</strong></td><td>" + this.datasets[0].tank_Test_Due + "</td></tr>";
    }else
    {
      strEv = strEv + "<tr><td><strong>Tank_Test_Due:</strong></td><td>" +''+ "</td></tr>";
    }
    if (  this.datasets[0].tare_Weight  != undefined)
    {
      strEv = strEv + "<tr><td><strong>Tare_Weight:</strong></td><td>" + this.datasets[0].tare_Weight + "</td></tr>";
    }else
    {
      strEv = strEv + "<tr><td><strong>Tare_Weight:</strong></td><td>" +''+ "</td></tr>";
    }
    if (  this.datasets[0].tickness_Test   != undefined)
    {
      strEv = strEv + "<tr><td><strong>Tickness_Test:</strong></td><td>" + this.datasets[0].tickness_Test + "</td></tr>";
    }else
    {
      strEv = strEv + "<tr><td><strong>Tickness_Test:</strong></td><td>" +''+ "</td></tr>";
    }
    if (  this.datasets[0].top_unload_valve_size   != undefined)
    {
      strEv = strEv + "<tr><td><strong>Top_unload_valve_size:</strong></td><td>" + this.datasets[0].top_unload_valve_size + "</td></tr>";
    }else
    {
      strEv = strEv + "<tr><td><strong>Top_unload_valve_size:</strong></td><td>" +''+ "</td></tr>";
    }
    if (   this.datasets[0].top_unload_valve_type    != undefined)
    {
      strEv = strEv + "<tr><td><strong>Top_unload_valve_type:</strong></td><td>" + this.datasets[0].top_unload_valve_type + "</td></tr>";
    }else
    {
      strEv = strEv + "<tr><td><strong>Top_unload_valve_type:</strong></td><td>" +''+ "</td></tr>";
    }
    if (    this.datasets[0].top_vent_valve_size    != undefined)
    {
      strEv = strEv + "<tr><td><strong>Top_vent_valve_size:</strong></td><td>" + this.datasets[0].top_vent_valve_size + "</td></tr>";
    }else
    {
      strEv = strEv + "<tr><td><strong>Top_vent_valve_size:</strong></td><td>" +''+ "</td></tr>";
    }
    if ( this.datasets[0].top_vent_valve_type != undefined)
    {
      strEv = strEv + "<tr><td><strong>Top_vent_valve_type:</strong></td><td>" + this.datasets[0].top_vent_valve_type + "</td></tr>";
    }else
    {
      strEv = strEv + "<tr><td><strong>Top_vent_valve_type:</strong></td><td>" +''+ "</td></tr>";
    }
    if (  this.datasets[0].total_Weight  != undefined)
    {
      strEv = strEv + "<tr><td><strong>Total_Weight:</strong></td><td>" + this.datasets[0].total_Weight + "</td></tr>";
    }else
    {
      strEv = strEv + "<tr><td><strong>Total_Weight:</strong></td><td>" +''+ "</td></tr>";
    }
    if ( this.datasets[0].weight_Unit != undefined)
    {
      strEv = strEv + "<tr><td><strong>Weight_Unit:</strong></td><td>" + this.datasets[0].weight_Unit + "</td></tr>";
    }else
    {
      strEv = strEv + "<tr><td><strong>Weight_Unit:</strong></td><td>" +''+ "</td></tr>";
    }
    if (this.datasets[0].y_PMTMEQRL_PRD_O_RING_MATERIAL != undefined)
    {
      strEv = strEv + "<tr><td><strong>Y_PMTMEQRL_PRD_O_RING_MATERIAL:</strong></td><td>" + this.datasets[0].y_PMTMEQRL_PRD_O_RING_MATERIAL + "</td></tr>";
    }else
    {
      strEv = strEv + "<tr><td><strong>Y_PMTMEQRL_PRD_O_RING_MATERIAL:</strong></td><td>" +'' + "</td></tr>";
    }
    
    strEv = strEv + "</table>";   

    mywindow.document.write(strEv);
    mywindow.document.write('</body></html>');    
    mywindow.document.close();     mywindow.focus();
    mywindow.document.title = this.titile;
    mywindow.print();
    mywindow.close();

    return true;

  }

}
