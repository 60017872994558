import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from '../app/components/home/home.component';

import { LoginComponent } from './components/login/login.component';

import { LogoutComponent } from './components/logout/logout.component';
import { RailCarInformationComponent } from './components/rail-car-information/rail-car-information.component';
import { RailCarInfoSelectionComponent } from './components/rail-car-info-selection/rail-car-info-selection.component';
import { RailCarModifyComponent } from './components/rail-car-modify/rail-car-modify.component';
import { ViewAttachmentsComponent } from './components/view-attachments/view-attachments.component';
import { AdminComponent } from './components/admin/admin.component';
import { UserManagementComponent } from './components/user-management/user-management/user-management.component';
import { RailcarUrlsComponent } from './components/railcar-urls/railcar-urls.component';
import { RailcarLinksComponent } from './components/railcar-links/railcar-links.component';
import { RailcarurlsMasterComponent } from './railcarurls-master/railcarurls-master/railcarurls-master.component';
import { DocumentsLinkComponent } from './documents-link/documents-link.component';
import { DocumenturlsMasterComponent } from './documenturls-master/documenturls-master/documenturls-master.component';
import { OverviewComponent } from './components/overview/overview.component';
import { AdminNotificationComponent } from './components/admin-notification/admin-notification.component';
import { AuthGuard } from './_helpers/auth.guard';
import { InvoiceSelectionComponent } from './components/invoice-selection/invoice-selection.component';
import { InvoiceInformationComponent } from './components/invoice-information/invoice-information.component';
import { LoginTestComponent } from './components/login-test/login-test.component'
import { HistoricalMaintenanceComponent } from './components/historical-maintenance/historical-maintenance.component';
const routes: Routes = [
  {
    path: '', pathMatch: 'full', redirectTo: '/home'
  },
  {
    path: 'home', canActivate: [AuthGuard],component: HomeComponent
  },
  {
    path: 'rail-car',canActivate: [AuthGuard], component: RailCarInformationComponent
  },
  {
    path: 'rail-car/info',canActivate: [AuthGuard], component: RailCarModifyComponent
  },
  {
    path: 'active-cars',canActivate: [AuthGuard], component: RailCarInfoSelectionComponent
  },
  {
    path: 'invoice',canActivate: [AuthGuard], component: InvoiceInformationComponent
  },
  {
    path: 'overview',canActivate: [AuthGuard], component: OverviewComponent
  },
  {
    path: 'login', component: LoginComponent
  },
  {
    path: 'view-attachment',canActivate: [AuthGuard], component: ViewAttachmentsComponent
  }, 
  {
    path: 'logout',canActivate: [AuthGuard], component: LogoutComponent
  }, 
  {
    path: 'admin',canActivate: [AuthGuard], component: AdminComponent
  },
  {
    path: 'usermanagement',canActivate: [AuthGuard], component:UserManagementComponent
  },
  {
    path: 'notification', component:AdminNotificationComponent
  },
  {
    path: 'notification', component:AdminNotificationComponent
  },
  {
    path: 'railcar-urls', component:RailcarUrlsComponent
  },
  {
    path: 'railcar-links',canActivate: [AuthGuard], component:RailcarLinksComponent //General
  },
  {
    path: 'railcarlinks-master',canActivate: [AuthGuard], component:RailcarurlsMasterComponent
  },
  {
    path: 'documents-links',canActivate: [AuthGuard], component:DocumentsLinkComponent //General 
  },
  {
    path: 'documentslinks-master',canActivate: [AuthGuard], component:DocumenturlsMasterComponent 
  },
  {
    path: 'historical-maintenance', canActivate: [AuthGuard], component: HistoricalMaintenanceComponent
  },
  //{
  //  path: 'loginTest', component: LoginTestComponent
  //}

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
