import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DialogpopupComponent } from 'src/app/common/dialogpopup/dialogpopup.component';
import { RailFleetService } from 'src/services/rail-fleet.service';
import { RailcarurlsFormComponent } from '../railcarurls-form/railcarurls-form.component';

@Component({
  selector: 'app-railcarurls-master',
  templateUrl: './railcarurls-master.component.html',
  styleUrls: ['./railcarurls-master.component.css']
})
export class RailcarurlsMasterComponent implements OnInit {

  dataSource = new MatTableDataSource();
  apires:any;
  role:any;
  userNotFound=false;
  constructor(private railfleetservice: RailFleetService,private toastr: ToastrService, private spinner: NgxSpinnerService, private matdialog: MatDialog, private router: Router) { }
  

  displayedColumns = ['descriptionName', 'url','Edit','Remove'];

  ngOnInit(): void {
    this.role=sessionStorage.getItem('role');
    if(!(this.role!='Admin'))
    {
      this.getAllRailCarUrls();
    }else{
      this.userNotFound=true;
      this.railfleetservice.setNavmenustatus(false);
    }
    
  }
  getAllRailCarUrls() {
    this.dataSource.data = [];
    this.railfleetservice.getAllRailCarUrls().subscribe((res: any) => {
      this.apires=res;
      this.dataSource.data = this.apires;
    })
  }

  refresh() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['/railcarlinks-master']);
  }

  onAddUser() {
    //write a component form
    var dialogref = this.matdialog.open(RailcarurlsFormComponent, { data: { isModify: false } });
    dialogref.afterClosed().subscribe(res => {
      this.getAllRailCarUrls();
    });
  }
  
    applyFilter(event: Event) {
      const filterValue = (event.target as HTMLInputElement).value;
      this.dataSource.filter = filterValue.trim().toLowerCase();
    }
  
  onEditUser(railcarurl:any) {  
   
    var dialogref = this.matdialog.open(RailcarurlsFormComponent, {
      data: {
        isModify: true,
        newUser: false,
        urlID:railcarurl.urlID,
        descriptionName: railcarurl.descriptionName,
        url: railcarurl.url,
      }
    });
    dialogref.afterClosed().subscribe(res => {
      this.refresh();
    });
  }

  onRemoveUser(railcarurl: any) {
    var record=[];
    var dialogref = this.matdialog.open(DialogpopupComponent, { data: { message: 'Are you sure you want to delete user ' + railcarurl.descriptionName, title: 'Confirm Delete' } })
    dialogref.afterClosed().subscribe(res => {
      if (res) {
        const acPayLoad = {
        urlID:railcarurl.urlID,
        descriptionName: railcarurl.descriptionName,
        url: railcarurl.url,
        actionBy: "Prabhu",
        action: "Deactivate"
        }
        this.railfleetservice.updateRailCarUrls(acPayLoad).subscribe(res => {
          this.toastr.success("RailCar Url removed successfully");
          this.getAllRailCarUrls();
        });
      }
    });
    
  }
  onGoToPage2(data:any)
    {
      window.open(data, '_blank');
    }
    onRailCar()
    {
      this.router.navigate(['/railcarlinks-master']);
    }
    onDocument()
    {
      this.router.navigate(['/documentslinks-master']);
    }

}
