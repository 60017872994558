import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { UserFormComponent } from 'src/app/components/user-management/user-form/user-form.component';
import { RailFleetService } from 'src/services/rail-fleet.service';
import { DialogrequestComponent } from '../dialogrequest/dialogrequest.component';

@Component({
  selector: 'app-request-access',
  templateUrl: './request-access.component.html',
  styleUrls: ['./request-access.component.css']
})
export class RequestAccessComponent implements OnInit {
userForm: FormGroup;
  bus: any;
  constructor(@Inject(MAT_DIALOG_DATA) private Data: any, private dialogRef: MatDialogRef<RequestAccessComponent>,private matdialog: MatDialog, private _service: RailFleetService, private _toast: ToastrService
  ,private spinner:NgxSpinnerService,private _toastr:ToastrService) {

  }

  ngOnInit(): void {
    this.userForm = new FormGroup({
      basfUserID: new FormControl(this.Data.basfUserID, [Validators.required]),
      firstName: new FormControl(this.Data.firstName, [Validators.required]),
      lastName: new FormControl(this.Data.lastName, [Validators.required]),
      Email: new FormControl(this.Data.email, [Validators.required, Validators.email]),
      buID: new FormControl('', [Validators.required]),
    });
    this.GetAllBus();
  }

  GetAllBus() {
    this._service.GetAllBus().subscribe((data: any[]) => {
      // console.log(data);
      this.bus = data;
    });
  }
  public hasError = (controlName: string, errorName: string) => {
    return this.userForm.controls[controlName].hasError(errorName);
  }
  Sendemail()
  {
     this.onSend();
    this.onEmailSend();
  }
  onSend() {
    // console.log(this.userForm.valid);
    if (this.userForm.valid) {
      const acPayLoad = {
        userID: this.Data.userID ? this.Data.userID : 0,
        basfUserID: this.userForm.controls.basfUserID.value,
        firstName: this.userForm.controls.firstName.value,
        lastName: this.userForm.controls.lastName.value,
        roleID: 2,
        Email: this.userForm.controls.Email.value,
        buID: this.userForm.controls.buID.value,
        actionBy: this.userForm.controls.basfUserID.value,
        action: "Request"
      }
      acPayLoad.buID = acPayLoad.buID.join(',');
      // console.log(acPayLoad);
      this._service.updateUser_new(acPayLoad).subscribe(res => { 
        this._toast.success("Your Details Submitted Successfully") 
      this.matdialog.open(DialogrequestComponent,
        { data: { message: 'Your request is sent. Please note approval may take up to 4 hours.', title: acPayLoad.firstName } })
      });
    }

    this.dialogRef.close();
  }
  onEmailSend() {
    if (this.userForm.valid) {
      const acPayLoad = {
        userID: this.Data.userID ? this.Data.userID : 0,
        basfUserID: this.userForm.controls.basfUserID.value,
        firstName: this.userForm.controls.firstName.value,
        lastName: this.userForm.controls.lastName.value,
        roleID: 2,
        Email: this.userForm.controls.Email.value,
        buID: this.userForm.controls.buID.value,
        actionBy: this.userForm.controls.basfUserID.value,
        action: "Request"
      }
      acPayLoad.buID = acPayLoad.buID.join(',');
      this._service.mailRequest(acPayLoad).subscribe(res => { this._toast.success("Your Details Submitted Successfully") });
    }

    this.dialogRef.close();
  }

}
