import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { RailFleetService } from 'src/services/rail-fleet.service';
import { NgxSpinnerService } from 'ngx-spinner';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-rail-car-modify',
  templateUrl: './rail-car-modify.component.html',
  styleUrls: ['./rail-car-modify.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class RailCarModifyComponent implements OnInit {
  labelData: any;
  rowData: any;
  orgRowData: any;
  type: any;
  masterAPIResp: any;
  updateAPIResp: any;
  permittedCommodities: any;
  permittedCommodityValue: any;
  packingGroups: any;
  packagingGroupValue: any;
  hazardClasses: any;
  hazardClassValue: any;
  carTypes: any;
  carTypeValue: any;
  dot_aar_classes: any;
  dot_aar_classValue: any;
  carBuilders: any;
  carBuilderValue: any;
  selectedPermittedCommodity: any[];
  selectedPackinggroup: any;
  selectedHazardClass: any;
  selectedCarBuilder: any;
  display: boolean = false;
  validFixedAssset = true;
  validMonthlyDepreciation = true;
  validRent = true;
  validRepPaint = true;
  dialogHeader = "";
  labelName = "";
  labelDescription = "";
  ddName = "";
  ddDescription = "";
  ddType = "";
  selectedPC: any;
  apiResp: any;
  userId: any;
  Arole:any;
  userAdmin: boolean = false;
  userInfo:any;
  constructor(private router: Router, private _service: RailFleetService, private _spinner: NgxSpinnerService, private _toastr: ToastrService) { }

  ngOnInit() {
    this.userId = sessionStorage.getItem('userId');
    let role = sessionStorage.getItem('role');
    if(role != null && role != undefined && role != '' ) {
      if(role.includes('Admin')) {
        this.userAdmin = true;
        this.Arole=1;
      } else {
        this.userAdmin = false;
      }
    }  else {
      this.userAdmin = false;
    }
    if (this.userId != null && this.userId != 'undefined' && this.userId != '') {
      if (!history.state.data) {
        this.router.navigate(['/rail-car']);
      } else {
        this.labelData = history.state.data.cols;
        this.orgRowData = history.state.data.rowData;
        this.rowData = JSON.parse(JSON.stringify(this.orgRowData));
        //this.computeRentalAgreement();
        this.type = history.state.data.selectionType;
        if (this.type == 'edit') {
          this.getAllMasterData();
        }
      }
    } else {
      this.router.navigate(['/login']);
    }
  }

  backToGrid() {
    this.router.navigate(['/rail-car']);
  }

  editRowData() {
    this.type = "edit";
    this.getAllMasterData();
    this.clearValidations();
  }

  cancel() {
    this.rowData = JSON.parse(JSON.stringify(this.orgRowData));
    this.type = 'view';
  }

  getAllMasterData() {
    this._spinner.show();
    this._service.getRailMasterData().subscribe(response => {
      this.masterAPIResp = response;
      this.permittedCommodities = this.masterAPIResp.permittedCommodities;
      this.permittedCommodities.forEach(pc => {
        pc.railcarID = this.rowData.railcarID;
        pc.permittedCommoditiesDesc = null;
      })
      this.packingGroups = this.masterAPIResp.packagingGroups;
      this.hazardClasses = this.masterAPIResp.hazardClasses;
      this.carTypes = this.masterAPIResp.carTypes;
      this.dot_aar_classes = this.masterAPIResp.dot_aar_classes;
      this.carBuilders = this.masterAPIResp.carBuilders;
      this.assignDropDownValues();
      this._spinner.hide();
    })
  }

  assignDropDownValues() {
    this.selectedPermittedCommodity = this.rowData.permittedCommodity;
    this.selectedPackinggroup = this.packingGroups.find(pg => pg.packingGroupID === this.rowData.packingGroupID);
    this.selectedCarBuilder = this.carBuilders.find(cb => cb.carBuilderID === this.rowData.carBuilderID);
    this.selectedHazardClass = this.hazardClasses.find(hc => hc.hazardClassID === this.rowData.hazardClassID);
  }

  changePermittedCommodity() {
    //console.log(this.selectedPermittedCommodity);
    // this.rowData.permittedCommoditiesID = this.selectedPermittedCommodity.permittedCommoditiesID;
    // this.rowData.permittedcommodities = this.selectedPermittedCommodity.permittedcommodities;
  }

  changePackingGroup() {
    this.rowData.packingGroupID = this.selectedPackinggroup.packingGroupID;
    this.rowData.packinggroup = this.selectedPackinggroup.packinggroup;
  }

  changeHazardClass() {
    this.rowData.hazardClassID = this.selectedHazardClass.hazardClassID;
    this.rowData.hazardclass = this.selectedHazardClass.hazardclass;
  }

  changeCarBuilder() {
    this.rowData.carBuilderID = this.selectedCarBuilder.carBuilderID;
    this.rowData.carbuilder = this.selectedCarBuilder.carbuilder;
  }

  openPopup(value) {
    this.display = true;
    this.labelName = "Name";
    this.labelDescription = "Description";
    switch (value) {
      case 'pc': this.dialogHeader = "Add Permitted Commodity";this.ddType="pc"; break;
      case 'pg': this.dialogHeader = "Add Packaging Group";this.ddType="pg"; break;
      case 'hc': this.dialogHeader = "Add Hazard Class";this.ddType="hc"; break;
      case 'cb': this.dialogHeader = "Add Car Builder";this.ddType="cb"; break;
      default: this.display = false; break;
    }
  }

  saveRailCar(railCar) {
    this._spinner.show();
    const railFleetClass = [{
      railcarID: railCar.railcarID,
      carStatus:railCar.carStatus,
      dueTest: railCar.dueTest,
      nextRegYr: railCar.nextRegYr,
      originalRCID: railCar.originalRCID,
      bu: railCar.bu,
      tcc: railCar.tcc,
      contract: railCar.contract,
      fixedAsset: railCar.fixedAsset,
      locationCode: railCar.locationCode,
      sortField:railCar.sortField,
      maintenancePlant:railCar.maintenancePlant,
      assignedLocation: railCar.assignedLocation,
      fleet: railCar.fleet,
      permittedCommodities: railCar.permittedCommodities,
      // packingGroup: railCar.packingGroup,
      // hazardClass: railCar.hazardClass,
      carType: railCar.carType,
      originalCertofConstruction: railCar.originalCertofConstruction,
      doT_AAR_Class: railCar.doT_AAR_Class,
      carBuilder: railCar.carBuilder,
      yearBuilt: railCar.yearBuilt,
      ageofCar: railCar.ageofCar,
      onHire: railCar.onHire,
      owner: railCar.owner,
      rider: railCar.rider,
      effective: railCar.effective,
      expiration: railCar.expiration,
      monthlyDepreciation: railCar.monthlyDepreciation,
      rent: railCar.rent,
      rep_Maint: railCar.rep_Maint,
      rentalAgreement: railCar.rentalAgreement.toString(),
      leaseTerm: railCar.leaseTerm,
      term: railCar.term,
      tareWeightLBS: railCar.tareWeightLBS,
      maxGrossWeight: railCar.maxGrossWeight,
      comments: railCar.comments,
      lastUpdatedDate: railCar.lastUpdatedDate,
      permittedCommoditiesID: railCar.permittedCommoditiesID,
      // packingGroupID: railCar.packingGroupID,
      // hazardClassID: railCar.hazardClassID,
      carBuilderID: railCar.carBuilderID,
      modifiedBy: "Nandha",
      permittedCommodity: this.selectedPermittedCommodity
    }];
    const payLoad = railFleetClass[0];
    if(this.validFixedAssset && this.validMonthlyDepreciation && this.validRent && this.validRepPaint) {
      this._service.updateRailFleetData(payLoad).subscribe(response => {
        this.updateAPIResp = response;
        this._toastr.success(this.updateAPIResp, 'Success!');
        this.type = 'view';
        this.clearValidations();
        this.getRailFleetDataDetails();
        this._spinner.hide();
      });
    }
  }

  checkValidation(fieldName, value) {
    switch(fieldName) {
      case 'fixedAsset': this.validFixedAssset = this.validateNumber(value);
                         break;
      case 'monthlyDepreciation': this.validMonthlyDepreciation = this.validateNumber(value);
                                  break;
      //case 'rentalAgreement': this.validRent = this.validateNumber(value);//this.computeRentalAgreement();
                   //break;
      case 'rep_Maint': this.validRepPaint = this.validateNumber(value);//this.computeRentalAgreement();
                        break;
      default: break;
    }
  }


  validateNumber(value) {
    let numberExp = new RegExp(/^\d*\.?\d*$/);
    return numberExp.test(value);
  }

  clearValidations() {
    this.validFixedAssset = true;
    this.validRepPaint = true;
    this.validRent = true;
    this.validMonthlyDepreciation = true;
  }

  updatePermittedCommodities() {
    this._spinner.show();
    const pcPayLoad = {
      permittedCommoditiesID: 0,
      permittedcommodities: this.ddName,
      permittedCommoditiesDesc: this.ddDescription,
      actionBy: "Nandha",
      action: "Insert"
    }
    this._service.updatePermittedCmmodities(pcPayLoad).subscribe(response => {
      this.getAllMasterData();
      this._toastr.success(response.toString(), "Success!");
      this.cancelDD();
      this._spinner.hide();
    })
  }

  updatePackagingGroups() {
    this._spinner.show();
    const pgPayLoad = {
      packingGroupID: 0,
      packinggroup: this.ddName,
      packingGroupDesc: this.ddDescription,
      actionBy: "Nandha",
      action: "Insert"
    }
    this._service.updatePackingGroup(pgPayLoad).subscribe(response => {
      this.getAllMasterData();
      this._toastr.success(response.toString(), "Success!");
      this.cancelDD();
      this._spinner.hide();
    })
  }

  updateHazardClasses() {
    this._spinner.show();
    const hcPayLoad = {
      hazardClassID: 0,
      hazardclass: this.ddName,
      hazardClassDesc: this.ddDescription,
      actionBy: "Nandha",
      action: "Insert"
    }
    this._service.updateHazardclass(hcPayLoad).subscribe(response => {
      this.getAllMasterData();
      this._toastr.success(response.toString(), "Success!");
      this.cancelDD();
      this._spinner.hide();
    })
  }

  updateCarBuilders() {
    this._spinner.show();
    const cbPayLoad = {
      carBuilderID: 0,
      carbuilder: this.ddName,
      carBuilderDesc: this.ddDescription,
      actionBy: "Nandha",
      action: "Insert"
    }
    this._service.updateCarBuilder(cbPayLoad).subscribe(response => {
      this.getAllMasterData();
      this._toastr.success(response.toString(), "Success!");
      this.cancelDD();
      this._spinner.hide();
    })
  }



  cancelDD() {
    this.display = false;
    this.ddName = "";
    this.ddDescription = "";
  }

  saveDropDown() {
    switch(this.ddType) {
      case 'pc': this.updatePermittedCommodities(); break;
      case 'pg': this.updatePackagingGroups();break;
      case 'hc': this.updateHazardClasses();break;
      case 'cb': this.updateCarBuilders();break;
      default : this.display = false; break;
    }
  }

  getRailFleetDataDetails() {
    this._spinner.show();
    this._service.getUserInfo(this.userId).subscribe(response => {
      this.userInfo = response;
      this.getallrailfleetData(this.userInfo[0].userID,this.Arole);
    })
    
  }
  getallrailfleetData(userId, role)
  {
    const pgPayLoad = {
      railFleetID: 0,
      userID: userId,
      isAdmin: role
    }
    this._service.getallrailfleetData(pgPayLoad).subscribe(response => {
      this.apiResp = response;
      this.rowData = this.apiResp[0];
      this.assignDropDownValues();
      this._spinner.hide();
    })
  }
}
