import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DynamicDialogRef } from 'primeng/api';
import { DynamicDialogConfig } from 'primeng/api';


@Component({
  selector: 'app-user-popup',
  templateUrl: './user-popup.component.html',
  styleUrls: ['./user-popup.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class UserPopupComponent implements OnInit {

  orgUserData: any;
  userData = {
    'userID': 0,
    'basfUserID': '',
    'firstName': '',
    'lastName': '',
    'roleName': '',
    'orgCode': '',
    'email': '',
    'positionTitle': 'testing',
    'countryID': 0,
    'positionID': 'test12',
    'createdBy': '',
    'assignHMUsers': [],
    'assignHRUsers': [],
    validBASFUserId: true,
    validFirstName: true,
    validLastName: true,
    validRoles: true,
    validOrgCode: true,
    validEmail: true,
    invalidEmail: true,
    validPositionTItle: true,
    validHMUsers: true,
    validHRUsers: true,
    validPositionCountry: true,
  };
  selectedRoles = [];
  usersData: any;
  hrUsers = [];
  hmUsers = [];
  selectedHRUsers: any[];
  filteredHRUsers: any[];
  filteredHMUsers: any[];
  selectedHMUsers: any[];
  apiRequest = {
    users: [],
    userRoles: []
  }
  selectedCountry: any;
  roles = [
    { id: 1, roleName: 'Admin' },
    { id: 2, roleName: 'HRManager' },
    { id: 3, roleName: 'HiringManager' }
  ];
  dispAssignHMUser = false;
  dispAssignHRUser = false;
  reporteesAPIResp: any;
  reportingAPIResp: any;
  tempAPIResp: any;
  apiResp: any;
  countries = [];
  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig, private spinner: NgxSpinnerService, private toastr: ToastrService) { }

  ngOnInit() {
    this.spinner.show();
    this.userData.createdBy = sessionStorage.getItem('userId');
    if (this.config.data.edit) {
      this.orgUserData = this.config.data.selectedUserData;
      this.userData.userID = this.orgUserData.userID;
      this.userData.basfUserID = this.orgUserData.basfUserID;
      this.userData.firstName = this.orgUserData.firstName;
      this.userData.lastName = this.orgUserData.lastName;
      this.userData.email = this.orgUserData.email;
      this.userData.orgCode = this.orgUserData.orgCode;
      //this.userData.positionTitle = this.orgUserData.positionTitle;
      this.userData.countryID = this.orgUserData.countryID;
      this.userData.roleName = this.orgUserData.roleName;
      this.selectedRoles = this.orgUserData.roleName.split(",");
      if (this.selectedRoles.includes('HRManager')) {
        this.dispAssignHMUser = true;
        this.getUserReportees();
      }
      if (this.selectedRoles.includes('HiringManager')) {
        this.dispAssignHRUser = true;
        this.getUserReporting();
      }
    }
    this.usersData = this.config.data.usersData;
    this.generatedUserData();
    this.generateHRUsers();
    this.generateHMUsers();
    this.getCountryList();
    this.spinner.show();
  }

  getCountryList() {
    const countryID = 0;
    // this.service.getJobCountryDetails(countryID).subscribe(response => {
    //   this.apiResp = response;
    //   this.countries = this.apiResp;
    //   if (this.config.data.edit) {
    //     this.generateCountryObj(this.userData.countryID);
    //   }
    // })
  }

  onChangeCountry(event) {
    this.userData.countryID = this.selectedCountry.countryID;
    
  }

  generateCountryObj(countryID) {
    this.selectedCountry = this.countries.find(country => country.countryID == countryID);
  }

  generatedUserData() {
    if (this.usersData) {
      this.userData = JSON.parse(JSON.stringify(this.userData));
    }
    //this.selectedRoles = this.userData.role.split(", ");
  }

  generateHRUsers() {
    this.tempAPIResp = this.usersData.filter(user => user.roleName === 'HRManager');
    this.tempAPIResp.forEach(user => {
      const hrObj = {
        userID: user.userID,
        basfUserID: user.basfUserID,
        firstName: user.firstName,
        lastName: user.lastName,
        roleName: user.roleName,
        fullName: `${user.firstName} ${user.lastName}`,
        isChecked: false
      };
      this.hrUsers.push(hrObj);
    });
    this.sortHRUsers();
  }

  sortHRUsers() {
    this.hrUsers.sort((a, b) => { return (a.isChecked === b.isChecked) ? 0 : a ? -1 : 1; });

  }

  // filterHRUsersMultiple(event) {
  //   let query = event.query;
  //   this.filteredHRUsers = this.filterHRUsers(query, this.hrUsers);
  // }

  // filterHRUsers(query, Users: any[]): any[] {
  //   //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
  //   let filtered: any[] = [];
  //   for (let i = 0; i < Users.length; i++) {
  //     let user = Users[i];
  //     if (user.fullName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
  //       filtered.push(user);
  //     }
  //   }
  //   return filtered;
  // }


  generateHMUsers() {
    this.tempAPIResp = this.usersData.filter(user => user.roleName === 'HiringManager');
    this.tempAPIResp.forEach(user => {
      const hmObj = {
        userID: user.userID,
        basfUserID: user.basfUserID,
        firstName: user.firstName,
        lastName: user.lastName,
        roleName: user.roleName,
        fullName: `${user.firstName} ${user.lastName}`,
        isChecked: false
      };
      this.hmUsers.push(hmObj);
    });
    this.sortHMUsers();
  }

  sortHMUsers() {
    this.hmUsers.sort((a, b) => { return (a.isChecked === b.isChecked) ? 0 : a ? -1 : 1; });

  }

  // filterHMUsersMultiple(event) {
  //   let query = event.query;
  //   this.filteredHMUsers = this.filterHRUsers(query, this.hmUsers);
  // }

  // filterHMUsers(query, Users: any[]): any[] {
  //   //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
  //   let filtered: any[] = [];
  //   for (let i = 0; i < Users.length; i++) {
  //     let user = Users[i];
  //     if (user.fullName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
  //       filtered.push(user);
  //     }
  //   }
  //   return filtered;
  // }

  submitUser() {
    // if(this.userData.basfUserID!='' && this.userData.firstName!='' && this.userData.lastName!='' && this.userData.email!='' && this.userData.countryID!=0 && this.userData.orgCode!='' && this.selectedRoles.length!=0&&this.userData.invalidEmail==true){
   
    //   this.userData.userID = 0;
    // this.apiRequest.users.push(this.userData);
    // this.apiRequest.userRoles = this.buildUserRoles();
    // this.service.updateUser(this.apiRequest).subscribe(response => {
    //   this.toastr.success("User Added Successfully", "Success!");
    //   this.ref.close();
    // })}else{
    //   this.toastr.warning("Please Enter Required Fields", "Warning!");
    // }
  }

  buildUserRoles() {
    let userRoles = [];
    if (this.selectedRoles.includes('HiringManager')) {
      const roleId = this.roles.find(role => role.roleName === 'HiringManager').id;
      const roleName = 'HiringManager';
      let selHrUsers = [];
      this.selectedHRUsers.forEach(user => {
        const userObject = {
          reportingID: user.userID,
          positionID: 123,
          positionTitle: "testing"
        };
        selHrUsers.push(userObject);
      })
      let userObj = {
        "userRoleID": roleId,
        "userRole": roleName,
        "positionID": 12345,
        "positionTitle": "NantestTitleHR",
        "userList": "",
        "reportings": selHrUsers
      };
      userRoles.push(userObj);
    }
    if (this.selectedRoles.includes('HRManager')) {
      const roleId = this.roles.find(role => role.roleName === 'HRManager').id;
      const roleName = 'HRManager';
      let userIds = this.selectedHMUsers.map(user => user.userID);
      let userStrings = userIds.toString();
      let userObj = {
        "userRoleID": roleId,
        "userRole": roleName,
        "positionID": 12345,
        "positionTitle": "NantestTitleHR",
        "userList": userStrings,
        "reportings": []
      };
      userRoles.push(userObj);
    }
    if (this.selectedRoles.includes('Admin')) {
      const roleId = this.roles.find(role => role.roleName === 'Admin').id;
      const roleName = 'Admin';

      let userAdminObj = {
        "userRoleID": roleId,
        "userRole": roleName,
        "positionID": 12345,
        "positionTitle": "NantestTitleHR",
        "userList": "",
        "reportings": []
      };
      userRoles.push(userAdminObj);
    }
    return userRoles;
  }

  updateUser() {
    this.apiRequest.users.push(this.userData);
    this.apiRequest.userRoles = this.buildUserRoles();
    // this.service.updateUser(this.apiRequest).subscribe(response => {
    //   this.toastr.success("Userd Updated Successfully", "Success!");
    //   this.ref.close();
    // });
  }

  changedRole(event, userRole) {
    switch (userRole) {
      case 'HR': this.dispAssignHMUser = event;
        this.selectedRoles = this.selectedRoles.filter(e => e != 'Admin');
        break;
      case 'HM': this.dispAssignHRUser = event; this.selectedRoles = this.selectedRoles.filter(e => e != 'Admin'); break;
      case 'Admin':
        this.dispAssignHMUser = false;
        this.dispAssignHRUser = false;
        this.selectedRoles = this.selectedRoles.filter(e => e != 'HiringManager' && e != 'HRManager');
        this.toastr.warning("You cannot select HR or Hiring Manager when you are Admin", "Warning!")
        break;
    }
  }

  getUserReportees() {
    var selectedUsers = [];
    // this.service.getUserReportees(this.userData.basfUserID).subscribe(response => {
    //   this.reporteesAPIResp = response;
    //   //this.reporteesAPIResp = this.reporteesAPIResp.filter(user => user.roleName == 'HiringManager');
    //   this.reporteesAPIResp.forEach(user => {
    //     user.fullName = `${user.firstName} ${user.lastName}`;
    //     user.isChecked = true;
    //     user.roleName = 'HiringManager';//TODO in Backend
    //     this.hmUsers.forEach(hmUser => {
    //       if (hmUser.userID === user.userID) {
    //         hmUser.isChecked = true;
    //         if (hmUser.roleName === user.roleName && selectedUsers.findIndex(x => x == hmUser) == -1) {
    //           selectedUsers.push(hmUser);
    //         }
    //       }
    //     })
    //   });

    //   if (selectedUsers.length > 0) {
    //     var unSelectedUsers = this.hmUsers.filter(x => !x.isChecked);
    //     this.hmUsers = JSON.parse(JSON.stringify(selectedUsers.concat(unSelectedUsers)));
    //   }

    //   this.selectedHMUsers = JSON.parse(JSON.stringify(this.reporteesAPIResp));
    //   this.selectedHMUsers = this.selectedHMUsers.filter((v, i, a) => a.findIndex(t => (t.userID === v.userID)) === i); //&& t.roleName == 'HiringManager')) === i);
    //   this.sortHMUsers();
    // });

  }

  getUserReporting() {
    var selectedUsers = [];
    // this.service.getUserReporting(this.userData.basfUserID).subscribe(response => {
    //   this.reportingAPIResp = response;
    //   //this.reporteesAPIResp = this.reportingAPIResp.filter(user => user.roleName == 'HRManager');
    //   this.reportingAPIResp.forEach(user => {
    //     user.fullName = `${user.firstName} ${user.lastName}`;
    //     user.isChecked = true;
    //     user.roleName = 'HRManager';//TODO in Backend
    //     this.hrUsers.forEach(hrUser => {
    //       if (hrUser.userID === user.userID) {
    //         hrUser.isChecked = true;
    //         if (hrUser.roleName === user.roleName && selectedUsers.findIndex(x => x == hrUser) == -1) {
    //           selectedUsers.push(hrUser);
    //         }
    //       }
    //     })
    //   });

    //   if (selectedUsers.length > 0) {
    //     var unSelectedUsers = this.hrUsers.filter(x => !x.isChecked);
    //     this.hrUsers = JSON.parse(JSON.stringify(selectedUsers.concat(unSelectedUsers)));
    //   }

    //   this.selectedHRUsers = JSON.parse(JSON.stringify(this.reportingAPIResp));
    //   this.selectedHRUsers = this.selectedHRUsers.filter((v, i, a) => a.findIndex(t => (t.userID === v.userID)) === i); // && t.roleName == 'HRManager'))===i);
    //   this.sortHRUsers();
    // })
  }

  checkValidation(fieldName) {
    
    switch (fieldName) {     
      case 'BASFId': this.userData.validBASFUserId = !!(this.userData.basfUserID); break;
      case 'FN': this.userData.validFirstName = !!(this.userData.firstName); break;
      case 'LN': this.userData.validLastName = !!(this.userData.lastName); break;
    }
  }
  
  changeHMUserValue(selectedUsers) {
    selectedUsers.forEach(sUser => {
      this.hmUsers.forEach(hmUser => {
        if (hmUser.userID == sUser.userID) {
          hmUser.isChecked = true;
        }
      })
    });
    this.sortHMUsers();
  }

  changeHRUserValue(selectedUsers) {
    selectedUsers.forEach(sUser => {
      this.hrUsers.forEach(hrUser => {
        if (hrUser.userID == sUser.userID) {
          hrUser.isChecked = true;
        }
      })
    });
    this.sortHRUsers();
  }
  cancel(){
    this.userData.basfUserID='';
    this.userData.firstName='';
    this.userData.lastName='';
    this.userData.email='';
    this.userData.orgCode='';

  }
  Reset(){
    if (this.config.data.edit) {
      this.orgUserData = this.config.data.selectedUserData;
      this.userData.userID = this.orgUserData.userID;
      this.userData.basfUserID = this.orgUserData.basfUserID;
      this.userData.firstName = this.orgUserData.firstName;
      this.userData.lastName = this.orgUserData.lastName;
      this.userData.email = this.orgUserData.email;
      this.userData.orgCode = this.orgUserData.orgCode;
      //this.userData.positionTitle = this.orgUserData.positionTitle;
      this.userData.countryID = this.orgUserData.countryID;
      this.userData.roleName = this.orgUserData.roleName;
      this.selectedRoles = this.orgUserData.roleName.split(",");
      if (this.selectedRoles.includes('HRManager')) {
        this.dispAssignHMUser = true;
        this.getUserReportees();
      }
      if (this.selectedRoles.includes('HiringManager')) {
        this.dispAssignHRUser = true;
        this.getUserReporting();
      }
    }
  }
  checkBASFIdValidation() {  
    if(this.userData.basfUserID ==''){
      this.userData.validBASFUserId =false;      
    }else{
      this.userData.validBASFUserId =true;
    }
  }
  checkFirstNameValidation() {  
    if(this.userData.firstName ==''){
      this.userData.validFirstName =false;      
    }else{
      this.userData.validFirstName =true;
    }
  }
  checkLastNameValidation() {  
    if(this.userData.lastName ==''){
      this.userData.validLastName =false;      
    }else{
      this.userData.validLastName =true;
    }
  }
  checkEmailValidation() {  
    if(this.userData.email ==''){
      this.userData.validEmail =false;      
    }else{
      this.userData.validEmail =true;
      var reg = /^[A-Z0-9._%+-]+@([A-Z0-9-]+\.)+[A-Z]{2,4}$/i;
    if(reg.test(this.userData.email)){
       this.userData.invalidEmail=true;
    }else{
      this.userData.invalidEmail=false;
    }
    }
  }
  checkOrgCodeValidation() {  
    if(this.userData.orgCode ==''){
      this.userData.validOrgCode =false;      
    }else{
      this.userData.validOrgCode =true;
    }
  }
  checkCountryValidation() {  
    if(this.userData.countryID ==0){
      this.userData.validPositionCountry =false;      
    }else{
      this.userData.validPositionCountry =true;
    }
  }
  checkSelectedRolesValidation() {  
    if(this.selectedRoles.length ==0){
      this.userData.validRoles =false;      
    }else{
      this.userData.validRoles =true;
    }
  }

}
