import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { RailFleetService } from 'src/services/rail-fleet.service';

@Component({
  selector: 'app-admin-notification',
  templateUrl: './admin-notification.component.html',
  styleUrls: ['./admin-notification.component.css']
})
export class AdminNotificationComponent implements OnInit {

  form = new FormGroup({
    notificationMessage: new FormControl('', Validators.minLength(10)),
    lastDate: new FormControl(null, Validators.required),
  });
  showDialog: boolean = false;
  notifications = [];

  constructor(private _Service: RailFleetService) { }


  ngOnInit() {
    this.getNotificationData();
  }
  onFormSubmit() {
    if (!this.form.valid) {
      return;
    }
    let tDate = new Date(this.form.value.lastDate); //to be checked
    const data = { lastDate: tDate, notificationMessage: this.form.value.notificationMessage, createdBy: sessionStorage.getItem('userId')};
    // console.log(data);
    this._Service.addNotification(data).subscribe(val => {
      this.showDialog = false;
      this.getNotificationData();
    });
    // this.showDialog = false;
  }
  addNewNotification() {
    this.showDialog = true;
  }
  getNotificationData() {
    this._Service.getAllNotifications().subscribe((response: any) => {
      this.notifications = response;
    })
  }
}
