import { AfterViewChecked, AfterViewInit, Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Table } from 'primeng/table';
import { RailFleetService } from '../../../services/rail-fleet.service';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { SortEvent } from 'primeng/api';
import { Router } from '@angular/router';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

const EXCEL_EXTENSION = '.xlsx';

@Component({
  selector: 'app-historical-maintenance',
  templateUrl: './historical-maintenance.component.html',
  styleUrls: ['./historical-maintenance.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class HistoricalMaintenanceComponent implements OnInit, AfterViewInit, AfterViewChecked {
  cols: any[];
  sortedCols: any[];
  selectedCols = [];
  defaultWidth: '100%';
  viewColHeight: any;
  activeCarsData: any;
  filteredCols: any[] = [];
  orgActiveCarsData: any = [];
  filteredData: any;
  pushingPC: any;
  @ViewChild('dt', { static: false }) dt: Table;
  apiResp: any;
  userId: any;
  userAdmin: boolean = false;
  filteredFields = [];
  tempFilteredData: any;
  selectedValues: any = [];
  bulkEdit = false;
  permittedCommodities = [];
  tableUpdate = false;
  railcarIDCount = 0;
  sumTotal = 0;
  Arole = 0;
  yearOptions = [];
  selectedYears = [];
  userInfo: any;
  invoiceGlobalData: any = [];

  constructor(private _service: RailFleetService, private _spinner: NgxSpinnerService, private router: Router) {
  }

  ngOnInit() {
    this.userId = sessionStorage.getItem('userId');
    let role = sessionStorage.getItem('role');
    if (role != null && role != undefined && role != '') {
      if (role.includes('Admin')) {
        this.userAdmin = true;
        this.Arole = 1;
      } else {
        this.userAdmin = false;
      }
    } else {
      this.userAdmin = false;
    }
    if (this.userId != null && this.userId != 'undefined' && this.userId != '') {
      this.cols = [
        { field: 'railcarID', header: 'Railcar ID', options: [], isShow: true, width: this.defaultWidth, isEditable: false, serialNo: 1, selectedOptions: [], editMode: false },
        { field: 'bu', header: 'BU', options: [], isShow: true, width: this.defaultWidth, isEditable: false, serialNo: 2, selectedOptions: [], editMode: false },
        { field: 'fleet', header: 'Fleet', options: [], isShow: true, width: this.defaultWidth, isEditable: false, serialNo: 3, selectedOptions: [], editMode: false },
        { field: 'costCenter', header: 'Cost Center', options: [], isShow: true, width: this.defaultWidth, isEditable: false, serialNo: 4, selectedOptions: [], editMode: false },
        { field: 'glMapping', header: 'GL Mapping', options: [], isShow: true, width: this.defaultWidth, isEditable: false, serialNo: 5, selectedOptions: [], editMode: false },
        { field: 'mappingCategory', header: 'Mapping Category', options: [], isShow: true, width: this.defaultWidth, isEditable: false, serialNo: 6, selectedOptions: [], editMode: false },
        { field: 'laborCost', header: 'Labor Costs', options: [], isShow: true, width: this.defaultWidth, isEditable: false, serialNo: 7, selectedOptions: [], editMode: false },
        { field: 'materialCost', header: 'Material Costs', options: [], isShow: true, width: this.defaultWidth, isEditable: false, serialNo: 8, selectedOptions: [], editMode: false },
        { field: 'total', header: 'Total $', options: [], isShow: true, width: this.defaultWidth, isEditable: false, serialNo: 9, selectedOptions: [], editMode: false },
        { field: 'jobCodeCategory', header: 'Job Code Category', options: [], isShow: true, width: this.defaultWidth, isEditable: false, serialNo: 10, selectedOptions: [], editMode: false },
        { field: 'repairDate', header: 'Repair Date', options: [], isShow: true, width: this.defaultWidth, isEditable: false, serialNo: 11, selectedOptions: [], editMode: false },
        { field: 'narrative', header: 'Narrative', options: [], isShow: true, width: this.defaultWidth, isEditable: false, serialNo: 12, selectedOptions: [], editMode: false },
        { field: 'invoice', header: 'Invoice', options: [], isShow: true, width: this.defaultWidth, isEditable: false, serialNo: 13, selectedOptions: [], editMode: false },
        { field: 'invoiceCompany', header: 'Invoice Company', options: [], isShow: true, width: this.defaultWidth, isEditable: false, serialNo: 14, selectedOptions: [], editMode: false },
        { field: 'allT_Inv_Ref', header: 'ALLT INV REF', options: [], isShow: true, width: this.defaultWidth, isEditable: false, serialNo: 15, selectedOptions: [], editMode: false },
        { field: 'allT_Inv_Ref_Date', header: 'Date ALLT INV REF', options: [], isShow: true, width: this.defaultWidth, isEditable: false, serialNo: 16, selectedOptions: [], editMode: false }

      ];
      this.sortedCols = JSON.parse(JSON.stringify(this.cols));
      this.sortedCols = this.sortedCols.sort((a, b) => {
        return a.header > b.header ? 1 : -1;
      });
      this.sortedCols.forEach(element => {
        if (element.field != 'laborCost' && element.field != 'materialCost'
          && element.field != 'narrative' && element.field != 'invoice'
        )
          this.selectedCols.push(element);
      });
      localStorage.setItem('columns', JSON.stringify(this.selectedCols));
      this.viewColHeight = (window.innerHeight - 190) + "px";
      this.yearOptions = this.loadYears(2019);
      let selectedYrs = this.selectedYears.map(x => x.year).toString();
      this._service.getUserInfo(this.userId).subscribe(response => {
        this.userInfo = response;
        this._spinner.show();
        const payLoad = {
          userID: this.userInfo[0].userID,
          isAdmin: this.Arole,
          years: this.selectedYears.map(x => x.year).toString(),
          particalLoad: 1
        };
        this.getallrailfleetData(this.userInfo[0].userID, this.Arole, selectedYrs);
      });
    } else {
      this.router.navigate(['/login']);
    }
   
  }

  loadYears(startYear) {
    var currentYear = new Date().getFullYear(), years = [];
    let selectedYrs = [{ year: currentYear, selected: true }];
    this.selectedYears.push(...selectedYrs);
    startYear = startYear || 2018;
    while (startYear <= currentYear) {
      let yearObj = { year: startYear++, selected: true };
      years.push(yearObj);
    }
    return years;
  }

  onChangeDDValue(event) {

    let isRailcarID = event.value.find(x => x.field == 'railcarID') == undefined ? false : true;
    if (!isRailcarID) {
      this.sortedCols.forEach(element => {
        if (element.field == 'railcarID') {
          this.selectedCols.push(element);
        }
      });
    }

    if (event.value.length == 0) {
      this.sortedCols.forEach(element => {
        if (element.field == 'railcarID') {
          this.selectedCols.push(element);
        }
      });
    } else {
      if (event.itemValue && event.itemValue.field == 'railcarID') {
        if (isRailcarID) {
          this.sortedCols.forEach(element => {
            if (element.field == 'railcarID') {
              this.selectedCols.push(element);
            }
          });
        }
      }
    }
    this.sortColumns();
  }
  doubleClickCheck(data, col) {
    if (this.userAdmin || col.field == 'buNotes') {
      this.orgActiveCarsData.forEach(x => {
        if (x.railFleetID != data.railFleetID)
          x.editMode = false
      })
      this.selectedCols.forEach(x => {
        if (x.field != col.field)
          x.editMode = false
      })
      let editData = this.orgActiveCarsData.find(x => x.railFleetID == data.railFleetID)
      let colEdit = this.selectedCols.find(x => x.field == col.field)

      if ((!data.editMode && !col.editMode) || (data.editMode && !col.editMode)) {
        editData.editMode = true
        colEdit.editMode = true

      }
      else if (data.editMode && col.editMode) {
        editData.editMode = false
        colEdit.editMode = false
      }
      else {
        editData.editMode = true
      }

    } else {

    }
  }
  validateNumber(value) {
    let numberExp = new RegExp(/^\d*\.?\d*$/);
    return numberExp.test(value);
  }
  generatePayLoad(railCar, comments = '', buNotes = '', originalCertofConstruction = '') {
    let railComments, railBuNotes = '', railOriginalCertofConstruction = '';
    railComments = comments;
    railBuNotes = buNotes;
    railOriginalCertofConstruction = originalCertofConstruction;
    const railFleetClass = {
      railcarID: railCar.railcarID,
      carStatus: railCar.carStatus,
      dueTest: railCar.dueTest,
      nextRegYr: railCar.nextRegYr,
      currentRegCompleted: railCar.currentRegCompleted,
      originalRCID: railCar.originalRCID,
      bu: railCar.bu,
      buNotes: railBuNotes,
      tcc: railCar.tcc,
      contract: railCar.contract,
      fixedAsset: railCar.fixedAsset,
      locationCode: railCar.locationCode,
      sortField: railCar.sortField,
      maintenancePlant: railCar.maintenancePlant,
      assignedLocation: railCar.assignedLocation,
      fleet: railCar.fleet,
      // permittedCommodities: railCar.permittedCommodities,
      packingGroup: railCar.packinggroupSelected ? railCar.packinggroupSelected.packinggroup : null,
      hazardClass: railCar.hazardClassSelected ? railCar.hazardClassSelected.hazardclass : null,
      jacketed: railCar.jacketed,
      carType: railCar.carType,
      originalCertofConstruction: railCar.originalCertofConstruction,
      doT_AAR_Class: railCar.doT_AAR_Class,
      carBuilder: railCar.carbuilderSelected ? railCar.carbuilderSelected.carbuilder : '',
      yearBuilt: railCar.yearBuilt,
      ageofCar: railCar.ageofCar,
      onHire: railCar.onHire,
      owner: railCar.owner,
      rider: railCar.rider,
      effective: railCar.effective,
      expiration: railCar.expiration,
      monthlyDepreciation: railCar.monthlyDepreciation,
      rent: railCar.rent,
      rep_Maint: railCar.rep_Maint,
      rentalAgreement: railCar.rentalAgreement.toString(),
      leaseTerm: railCar.leaseTerm,
      term: railCar.term,
      tareWeightLBS: railCar.tareWeightLBS,
      maxGrossWeight: railCar.maxGrossWeight,
      comments: railComments,
      release: railCar.release,
      lastUpdatedDate: railCar.lastUpdatedDate,
      permittedCommoditiesID: railCar.permittedCommoditiesID, //
      packingGroupID: railCar.packinggroupSelected ? railCar.packinggroupSelected.packingGroupID : null,
      hazardClassID: railCar.hazardClassSelected ? railCar.hazardClassSelected.hazardClassID : null,
      carBuilderID: railCar.carbuilderSelected ? railCar.carbuilderSelected.carBuilderID : null,
      modifiedBy: this.userId,
      permittedCommodity: railCar.permittedCommodity
    };
    return railFleetClass
  }
  doFilter(val, field, reset?) {
    if (reset) {
      this.filteredCols = [];
    } else if (field != "") {
      let existingFilter = false;
      this.filteredCols.forEach(fc => {
        if (fc.field == field) {
          fc.value = val;
          existingFilter = true;
        }
      });
      if (!existingFilter) {
        this.filteredCols.push({
          name: this.cols.find(c => c.field == field).header,
          field: field,
          value: val
        });
      }
      if (val.length == 0) {
        this.filteredCols = this.filteredCols.filter(fcs => fcs.field !== field);
      }
    }
    this.orgActiveCarsData = JSON.parse(JSON.stringify(this.activeCarsData));
    if (this.filteredCols.length == 0) {
      this.updateMultiSelectDD(this.orgActiveCarsData, 0);
    } else {
      this.filteredCols.forEach((fc, index) => {
        let filteredData = [];
        fc.value.forEach(opt => {
          filteredData = [...filteredData, ...this.orgActiveCarsData.filter(ocd => ocd[fc.field] === opt)];
        });
        this.orgActiveCarsData = JSON.parse(JSON.stringify(filteredData));
        this.updateMultiSelectDD(this.orgActiveCarsData, index);
      });
    }
    this.railcarIDCount = this.orgActiveCarsData.filter((a, i) => this.orgActiveCarsData.findIndex((s) => a.railcarID === s.railcarID) === i).length;
    var sumT = this.orgActiveCarsData;
    if (this.orgActiveCarsData.length == 0) { this.sumTotal = 0 } else {
      this.sumTotal = 0;
      sumT.forEach(e => {
        if (e.total) {
          this.sumTotal += parseFloat(e.total);
        }
      });
    }
    localStorage.setItem('columns', JSON.stringify(this.selectedCols));
  }
  checkTheKey(e, data, col) {
    console.log(e);
    if (e.keyCode === 27) {
      this.orgActiveCarsData.forEach(x => x.editMode = false)
    }
    if (e.keyCode === 13) {
      console.log("Clicked on Enter");
      this.doubleClickCheck(data, col);
    }
  }
  tableFocusOut() {
    console.log("table focus out")

  }
  updateMultiSelectDD(rowsData, index) {
    this.sortedCols.forEach(o => {
      const filteredCol = this.filteredCols.find(fc => fc.field === o.field);
      const filteredColIndex = this.filteredCols.indexOf(filteredCol);
      if ((filteredColIndex == -1 || filteredColIndex > index)) {
        o.options = [];
        this.getFilterObject(rowsData, o.field).forEach(filteredobj => {
          if (filteredobj == 'Blanks') o.options.push({ label: filteredobj, value: '' });
          else o.options.push({ label: filteredobj, value: filteredobj });
        });
      }
    });
  }
  onRemoveChip(event) {
    this.doFilter([], event.field);
    this.dt._columns.forEach(col => {
      if (col.field == event.field) col.selectedOptions = [];
    });
  }
  ngAfterViewInit(): void {
    let selectedVal = [];
    if (this.dt) {
      if (localStorage.getItem('columns')) {
        this.selectedCols = JSON.parse(localStorage.getItem('columns'));
        this.sortedCols.forEach(element => {
          this.selectedCols.forEach(elm => {
            if (element.field === elm.field) {
              if (elm.selectedOptions.length != 0) {
                element.selectedOptions = elm.selectedOptions;
                selectedVal.push(element);
              } else {
                selectedVal.push(element);
              }

            }

          });
        });
        this.selectedCols = selectedVal;
        this.selectedCols.forEach(col => {
          if (col.field === 'permittedCommodity') {
            this.filter(col.selectedOptions)
          } else {
            this.dt.filter(col.selectedOptions, col.field, 'in');
          }
        });
      };
      this.sortColumns();
    }
  }
  ngAfterViewChecked(): void {
    let selectedVals = [];
    if (this.dt && this.tableUpdate) {
      this.tableUpdate = false;
      if (localStorage.getItem('columns')) {
        this.sortedCols.forEach(element => {
          this.selectedCols.forEach(elm => {
            if (element.field === elm.field) {
              selectedVals.push(element);
            }

          });
        });
        this.selectedCols = selectedVals;
        this.selectedCols.forEach(col => {
          if (col.field === 'permittedCommodity') {
            this.filter(col.selectedOptions)
          } else {
            this.dt.filter(col.selectedOptions, col.field, 'in');
          }
        });
        this.sortColumns();
      };
    }
  }
  showHideData(e) {
    this.cols = e;
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      let value1;
      let value2;
      if (event.field == 'fixedAsset' || event.field == 'contract' || event.field == 'monthlyDepreciation') {
        value1 = Number(data1[event.field]);
        value2 = Number(data2[event.field]);
      } else if (event.field == 'expiration' || event.field == 'effective' || event.field == 'lastUpdatedDate' || event.field == 'onHire') {
        value1 = data1[event.field] != '' ? Date.parse(data1[event.field]) : 0
        value2 = data2[event.field] != '' ? Date.parse(data2[event.field]) : 0
      }
      else {
        value1 = data1[event.field];
        value2 = data2[event.field];
      }

      let result = null;

      if (value1 == null && value2 != null)
        result = -1;
      else if (value1 != null && value2 == null)
        result = 1;
      else if (value1 == null && value2 == null)
        result = 0;
      else if (typeof value1 === 'string' && typeof value2 === 'string')
        result = value1.localeCompare(value2);
      else
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;

      return (event.order * result);
    });
    localStorage.setItem('columns', JSON.stringify(this.selectedCols));
  }
  sortColumns() {
    this.selectedCols = this.selectedCols.sort((a, b) => {
      return a.serialNo - b.serialNo;
    })
  }
  apply(event) {
    if (this.selectedYears.length == 2) {
      let disableChk = this.selectedYears.some(x => x.year.toString() === event.itemValue.year.toString());
      if (!disableChk) {
        return;
      }
    }
    if (this.selectedYears.length > 0) {
      this._spinner.show();
      const payLoad = {
        userID: this.userInfo[0].userID,
        isAdmin: this.Arole,
        years: this.selectedYears.map(x => x.year).toString(),
        particalLoad: 1
      };
      let year = "";
      if (this.selectedYears.length == 1) {
        year = this.selectedYears[0].year.toString();
      } else {
        year = event.itemValue.year.toString();
      }
      this.getallrailfleetData(this.userInfo[0].userID, this.Arole, year);
    } else {
      this.responseResult([]);
    }
  }

  getallrailfleetData(userId, role, years) {
    this._spinner.show();
    const payLoad = {
      userID: userId,
      isAdmin: role,
      years: years,
      particalLoad: 1
    }
    this._service.getInvoiceHistoryData(payLoad).subscribe((response: any) => {
      if (this.selectedYears.length > 1) {
        this.invoiceGlobalData.push(...response);
      } else {
        this.invoiceGlobalData = [];
        this.invoiceGlobalData.push(...response);
      }
      this.responseResult(this.invoiceGlobalData);
    });
  }

  responseResult(res: any) {
    this.apiResp = res;
    //this.railcarIDCount = this.apiResp.filter((a, i) => this.apiResp.findIndex((s) => a.railcarID === s.railcarID) === i).length;
    this.railcarIDCount = [...new Set(this.apiResp.map(obj => obj.railcarID))].length;
    var sumT = this.apiResp;
    if (this.apiResp.length == 0) { this.sumTotal = 0 } else {
      this.sumTotal = 0;
      sumT.forEach(e => {
        if (e.total) {
          this.sumTotal += parseFloat(e.total);
        }
      });
    }
    this.activeCarsData = this.apiResp;
    this.activeCarsData.forEach(x => (x.editMode = false));
    this.orgActiveCarsData = JSON.parse(JSON.stringify(this.activeCarsData));
    this.filteredData = JSON.parse(JSON.stringify(this.activeCarsData));
    this.sortedCols.forEach(o => {
      this.getFilterObject(this.activeCarsData, o.field).forEach(filteredobj => {
        if (o.options.filter(x => x.value == filteredobj).length == 0) {
          if (filteredobj == 'Blanks')
            o.options.push({ label: filteredobj, value: '' });
          else
            o.options.push({ label: filteredobj, value: filteredobj });
        }
      })
    });
    this.doFilter([], "");
    this._spinner.hide();

  }
  getFilterObject(fullObj, key) {
    const uniqChk = [];
    let blanks = false;
    fullObj.filter((obj) => {
      if (!uniqChk.includes(obj[key])) {
        if (obj[key] !== null && obj[key] !== '')
          uniqChk.push(obj[key]);
        else if (!uniqChk.includes('Blanks')) {
          blanks = true;
        }
      }
      return obj;
    });
    const numericColumns = ['total', 'laborCost', 'materialCost']
    const dateColumns = ['repairDate', 'allT_Inv_Ref_Date'];
    if (dateColumns.includes(key)) {
      uniqChk.sort(function (a, b) {
        //const aComps = a.split("/");
        //const bComps = b.split("/");
        let aDate = new Date(a);
        let bDate = new Date(b);
        return aDate.getTime() - bDate.getTime();
      });
    } else if (numericColumns.includes(key)) {
      uniqChk.sort((a, b) => {
        return ((Number(a) < Number(b)) ? -1 : 1)
      });
    } else {
      uniqChk.sort();
    }
    if (blanks) uniqChk.unshift('Blanks');
    return uniqChk;
  }
 
  filterData(value, filter) {
    if (value == '') {
      if (this.filteredFields.length) {
        this.filteredFields = this.filteredFields.filter(ff => ff.fieldName !== filter);
      } else {
        this.filteredFields = [];
      }
    } else {
      let foundField = this.filteredFields.filter(ff => ff.fieldName == filter);
      if (foundField.length) {
        this.filteredFields.forEach(ff => {
          if (ff.fieldName == filter) {
            ff.value = value;
          }
        })
      } else {
        let fieldObj = {
          fieldName: filter,
          value: value
        };
        this.filteredFields.push(fieldObj);
      }
    }
    if (this.filteredFields.length) {
      let temp = this.activeCarsData;
      this.filteredFields.forEach(ff => {
        temp = temp.filter(fd => fd[ff.fieldName].toUpperCase().includes(ff.value.toUpperCase()));
      })
      this.filteredData = temp;
    } else {
      this.filteredData = JSON.parse(JSON.stringify(this.activeCarsData));
    }
    this.tempFilteredData = JSON.parse(JSON.stringify(this.filteredData));
  }
  filter(value) {
    var record = [];
    let temp = this.filteredData;
    if (value.length !== 0) {
      temp.forEach(element => {
        element.permittedCommodity.forEach(pc => {

          if (value.includes(pc.permittedcommodities)) {
            if (!record.includes(element)) {
              if (element !== null && element !== '') {
                record.push(element);
              }
            }
          }
        });
      });
      this.activeCarsData = record
    } else {
      this.activeCarsData = temp;
    }
    return this.activeCarsData;
  }
  b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }
  exportExcel() {
    let data = this.dt.hasFilter() ? this.dt.filteredValue : this.dt.value;
    let tFilteredData = JSON.parse(JSON.stringify(data));
    this.exportAsExcelFile(tFilteredData, 'HistoricalInvoice', 'sheet1', this.selectedCols)
  }
  exportAsExcelFile(json: any[], excelFileName: string, sheetName: string, columnValues: any[]): void {
    var fieldValues: any[] = columnValues.map(field => field.field);
    var headerValues: string[] = columnValues.map(header => header.header);
    var displayedJson: any[] = [];
    if (json != null && json.length > 0) {
      for (let originalValue of json) {
        var jsonObject: any = {};
        for (let fieldValue of fieldValues) {
          jsonObject[fieldValue] = originalValue[fieldValue];
        }
        var jsonString = JSON.stringify(jsonObject);
        displayedJson.push(JSON.parse(jsonString));
      }
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(displayedJson);
      var range = XLSX.utils.decode_range(worksheet['!ref']);
      for (var C = range.s.c; C <= range.e.c; ++C) {
        var address = XLSX.utils.encode_col(C) + "1"; // <-- first row, column number C
        if (!worksheet[address]) continue;
        worksheet[address] = {
          v: headerValues[C],
          t: 's',
          s: {
            alignment: { textRotation: 90 },
            font: { bold: true }
          }
        };
      }
      XLSX.utils.sheet_add_aoa(worksheet, [[]], { origin: -1 });
      const workbook: XLSX.WorkBook = { Sheets: { [sheetName]: worksheet }, SheetNames: [sheetName] };
      const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, excelFileName);
    }

  }
  saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
  }
  editBulk() {
    this.bulkEdit = true;
  }
  reset(dt) {

    this.selectedCols.forEach(col => {
      col.selectedOptions = [];
    });
    localStorage.clear();

    dt.reset();
    this.filteredCols = [];
    this.selectedCols = [];
    this.sortedCols.forEach(element => {
      if (element.field != 'laborCost' && element.field != 'materialCost'
        && element.field != 'narrative'
      )
        this.selectedCols.push(element);
    });
    localStorage.setItem('columns', JSON.stringify(this.selectedCols));
    this.sortColumns();
    this.doFilter([], '', true);
    this.updateMultiSelectDD(this.orgActiveCarsData, 0);
  }
  bulkEditStatus(status) {
    this.bulkEdit = status;
    if (!this.bulkEdit) {
      this.tableUpdate = true;
    }
    this.getallrailfleetData(this.userInfo[0].userID, this.Arole, this.selectedYears.map(x => x.year).toString());
    this.selectedValues = [];
  }

}
