import { Component, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'app-invoice-total',
  templateUrl: './invoice-total.component.html',
  styleUrls: ['./invoice-total.component.css']
})
export class InvoiceTotalComponent implements OnChanges {

  @Input() nonActioned: any = []
  @Input() actioned: any = []
  @Input() autoApproved: any = []
  invoiceDetails = {}
  constructor() { }

  ngOnChanges(): void {
    //console.log("find total", this.nonActioned, this.actioned)
    this.findTotal()
  }

  findTotal() {
    let data: any = {}
    if (this.nonActioned[0])
      data.id = this.nonActioned[0].allT_Invoice_Ref
    else if (this.actioned[0])
      data.id = this.actioned[0].allT_Invoice_Ref
    else if (this.autoApproved[0])
      data.id = this.autoApproved [0].allT_Invoice_Ref
      
    data.sum = +(
      this.nonActioned.reduce((a, b) => +(a + parseFloat(b.total)).toFixed(2), 0) +
      this.actioned.filter(x => x.status == 'Accept').reduce((a, b) => +(a + parseFloat(b.total)).toFixed(2), 0)
      + this.autoApproved.reduce((a, b) => +(a + parseFloat(b.total)).toFixed(2), 0)
    ).toFixed(2)
    this.invoiceDetails = data
  }
}