import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { RailFleetService } from 'src/services/rail-fleet.service';

@Component({
  selector: 'app-rail-car-information',
  templateUrl: './rail-car-information.component.html',
  styleUrls: ['./rail-car-information.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class RailCarInformationComponent implements OnInit {
  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};
  userId: any;
  constructor(private router: Router, private _service: RailFleetService, private _spinner: NgxSpinnerService,) { }

  ngOnInit() {
    this.userId = sessionStorage.getItem('userId');
    if (this.userId != null && this.userId != 'undefined' && this.userId != '') {
    } else {
      this.router.navigate(['/login']);
    }
  }
  onItemSelect(item: any) {
    // console.log(item);
    // console.log(this.selectedItems);
  }
  OnItemDeSelect(item: any) {
    // console.log(item);
    // console.log(this.selectedItems);
  }
  onSelectAll(items: any) {
    // console.log(items);
  }
  onDeSelectAll(items: any) {
    // console.log(items);
  }
}
