import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { RailFleetService } from 'src/services/rail-fleet.service';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { RequestAccessComponent } from 'src/app/common/request-access/request-access.component';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  decryptedUser: any;
  encryptedUser: any;
  department: any;
  apiResp: any;
  userNotFound = false;
  userInfo: any;
  firstName: any;
  lastName: any;
  email: any;
  dataSource = new MatTableDataSource();
  apires:any;
  pending=false;
  constructor(private spinner: NgxSpinnerService,private matdialog: MatDialog, private toast: ToastrService, private route: ActivatedRoute, private router: Router, private _service: RailFleetService) { }

  ngOnInit() {
    this.spinner.show();
    this.decryptedUser = sessionStorage.getItem('userId'); 
    this.route.queryParams.subscribe(params => {
      this.encryptedUser = params['Username'];
      if (this.decryptedUser == "undefined" || this.decryptedUser == undefined || this.decryptedUser == null || this.decryptedUser == "null" || this.decryptedUser == "" || this.decryptedUser != sessionStorage.getItem('userId')) {
        if (this.encryptedUser == "undefined" || this.encryptedUser == undefined || this.encryptedUser == null || this.encryptedUser == "null" || this.encryptedUser == "") {
          this.SAMLService();
        } else {
          let reqData = {
            username: params['Username'],            
            firstname: params['firstname'],
            lastname:params['lastname'],
            email: params['email']
          } 
         this._service.getDecryptedUserDetails(reqData).subscribe(response => {
            this.apiResp = response;
            sessionStorage.setItem('userId',this.apiResp.username);
            sessionStorage.setItem('firstname',this.apiResp.firstname);
            sessionStorage.setItem('lastname',this.apiResp.lastname);
            sessionStorage.setItem('email',this.apiResp.email);
            this.verifyUserAuthorization(this.apiResp.username); 
          },  
              (error) => {
            this.toast.error(error, "Error!");
            this.spinner.hide();
          }); 
        }
      } else {
        this.verifyUserAuthorization(this.decryptedUser);
      }
     
    });
}
  verifyUserAuthorization(userID) {
    this._service.getUserInfo(userID).subscribe(response => {
      this.userInfo = response;
      if(this.userInfo.length!=0)
      {
        if (this.userInfo[0].roleName.includes('Admin')) {
          sessionStorage.setItem('role', 'Admin');
        }
        this.spinner.hide();
        this._service.setNavmenustatus(true);
        this.router.navigate(['/home']);        
      }else{
        this.userNotFound = true;
        this._service.setNavmenustatus(false);
      }     
    })
  }

  SAMLService() {
    this.spinner.show();
    this._service.getSAMLService().subscribe((result) => {
      this.spinner.hide();
      window.location.href = result.toString();
    },
      (error) => {
        this.toast.error(error, "Error!");
        this.spinner.hide();
      });
  } 
  onAddUser() {
    this._service.getUsers(sessionStorage.getItem('userId')).subscribe(response=>{
     this.userInfo=response;
     if(this.userInfo.length!=0)
     {
       if (this.userInfo[0].email.includes(sessionStorage.getItem('email'))) {
         this.pending=true;
         const acPayLoad = {
          userID: 0,
          basfUserID: sessionStorage.getItem('userId'),
          firstName: sessionStorage.getItem('firstname'),
          lastName: sessionStorage.getItem('lastname'),
          roleID: 2,
          Email: sessionStorage.getItem('email'),
          buID: 2,
          actionBy:sessionStorage.getItem('userId'),
          action: "Request"
        }
      this._service.mailRequest(acPayLoad).subscribe(res => { });
       }       
     }else{
       this.pending=false;
       var dialogref = this.matdialog.open(RequestAccessComponent, { data: { isModify: false,
        basfUserID:sessionStorage.getItem('userId'),firstName:sessionStorage.getItem('firstname'),
        lastName:sessionStorage.getItem('lastname'),
        email:sessionStorage.getItem('email')} });
        dialogref.afterClosed().subscribe(res => {
        });
       } 
      })
    
  }

}