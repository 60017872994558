import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogpopupComponent } from 'src/app/common/dialogpopup/dialogpopup.component';
import { RailFleetService } from 'src/services/rail-fleet.service';
import { UserFormComponent } from '../user-form/user-form.component';
import { ToastrService } from 'ngx-toastr';
import { element } from 'protractor';
import { analyzeAndValidateNgModules } from '@angular/compiler';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.css']
})
export class UserManagementComponent implements OnInit {
  dataSource = new MatTableDataSource();
  apires:any;
  colorchange:number;
  role:any;
  userNotFound=false;
  constructor(private railfleetservice: RailFleetService,private toastr: ToastrService, private spinner: NgxSpinnerService, private matdialog: MatDialog, private router: Router) { }
  

  displayedColumns = ['basfUserID', 'firstName','lastName', 'roleName', 'email', 'bu','Edit','Remove','Status'];

  ngOnInit(): void {
    this.role=sessionStorage.getItem('role');
    if(!(this.role!='Admin'))
    {
      this.getUsers_new();
    }else{
      this.userNotFound=true;
      this.railfleetservice.setNavmenustatus(false);
    }
  }
  getUsers_new() {
    this.dataSource.data = [];
    this.railfleetservice.getUsers_new().subscribe((res: any) => {
      this.apires = res;
      if (this.apires) {
        this.apires.forEach(res => {
          res.bu = res.getBuses.map(x => x.bu).join(' ');
        });
      }
      this.dataSource.data = this.apires;
    })
  }

  refresh() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['/usermanagement']);
  }
  
    applyFilter(event: any) {
      const filterValue = event;
      this.dataSource.filter = filterValue.trim().toLowerCase();
    }
  
  onEditUser(user:any) {  
   
    var dialogref = this.matdialog.open(UserFormComponent, {
      data: {
        isModify: true,
        userID:user.userID,
        basfUserID: user.basfUserID,
        firstName: user.firstName,
        lastName: user.lastName,
        roleID:user.getRoles[0].roleID,        
        email: user.email,
        buID: user.getBuses,
      }
    });
    dialogref.afterClosed().subscribe(res => {
      this.refresh();
    });
  }

  onRemoveUser(user: any) {
    var record=[];
    var dialogref = this.matdialog.open(DialogpopupComponent, { data: { message: 'Are you sure you want to delete user ' + user.firstName, title: 'Confirm Delete' } })
    dialogref.afterClosed().subscribe(res => {
      if (res) {
        const acPayLoad = {
        userID:user.userID,
        basfUserID: user.basfUserID,
        firstName: user.firstName,
        lastName: user.lastName,
        roleID: user.getRoles[0].roleID,        
        email: user.email,
        buID: user.getBuses,
        actionBy: user.basfUserID,
        action: "Deactivate"
        }
        acPayLoad.buID.forEach(element => {
          record.push(element.buID);
        });
        acPayLoad.buID=record.join(',')
        this.railfleetservice.updateUser_new(acPayLoad).subscribe(res => {
          this.toastr.success("user removed successfully");
          this.getUsers_new();
        });
      }
    });
    
  }
  onApprovedUser(user: any)
  {
    var record=[];
    var dialogref = this.matdialog.open(DialogpopupComponent, { data: { message: 'Are you sure you want to Approve user ' + user.firstName, title: 'Confirm Delete' } })
    dialogref.afterClosed().subscribe(res => {
      if (res) {
        const acPayLoad = {
        userID:user.userID,
        basfUserID: user.basfUserID,
        firstName: user.firstName,
        lastName: user.lastName,
        roleID: user.getRoles[0].roleID,        
        email: user.email,
        buID: user.getBuses,
        actionBy: user.basfUserID,
        action: "Approved"
        }
        acPayLoad.buID.forEach(element => {
          record.push(element.buID);
        });
        acPayLoad.buID=record.join(',')
        this.railfleetservice.updateUser_new(acPayLoad).subscribe(res => {
          this.toastr.success("user Approved successfully");
          this.getUsers_new();
        });
      }
    });
    
  }
  onUnApprovedUser(user: any)
  {
    var record=[];
    var dialogref = this.matdialog.open(DialogpopupComponent, { data: { message: 'Are you sure you want to unApprove user ' + user.firstName, title: 'Confirm Delete' } })
    dialogref.afterClosed().subscribe(res => {
      if (res) {
        const acPayLoad = {
        userID:user.userID,
        basfUserID: user.basfUserID,
        firstName: user.firstName,
        lastName: user.lastName,
        roleID: user.getRoles[0].roleID,        
        email: user.email,
        buID: user.getBuses,
        actionBy: user.basfUserID,
        action: "UnApproved"
        }
        acPayLoad.buID.forEach(element => {
          record.push(element.buID);
        });
        acPayLoad.buID=record.join(',')
        this.railfleetservice.updateUser_new(acPayLoad).subscribe(res => {
          this.toastr.success("user UnApproved successfully");
          this.getUsers_new();
        });
      }
    });
  }

}
