import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { RailFleetService } from 'src/services/rail-fleet.service';

import { AuthenticationServiceService } from '../../../services/authentication-service.service';
@Component({
  selector: 'app-login-test',
  templateUrl: './login-test.component.html',
  styleUrls: ['./login-test.component.css']
})
export class LoginTestComponent implements OnInit {

  user: any;
  usersData: any;
  userId:any;
  tokenInfo:any;
  loginAudiInfo:any;
  userInfo: any;
  constructor(private router: Router,  private spinner: NgxSpinnerService,private _authservice: AuthenticationServiceService, private _service: RailFleetService) { 
  }

  ngOnInit() {
    this.spinner.show();
    this._service.getUserInfo('All').subscribe(response => {
      this.usersData = response;
      this.spinner.hide();
    }); 
  }

  loginbtn() {
    sessionStorage.setItem('userId', this.user);
    
    this.userId = sessionStorage.getItem('userId');
     this.verifyUserAuthorization(this.userId);
    
    this._authservice.getToken(this.userId).subscribe(response => {
      this.tokenInfo=response;
      sessionStorage.setItem('email', this.userInfo.email);

      localStorage.setItem('TokenInfo', JSON.stringify(this.tokenInfo));
    });;
  }
  verifyUserAuthorization(userId) {
    this._service.getUserInfo(userId).subscribe(response => {
      this.userInfo = response;
      if(this.userInfo.length!=0)
      {
        if (this.userInfo[0].roleName.includes('Admin')) {
          sessionStorage.setItem('role', 'Admin');
        }
        this.spinner.hide();
        this._service.setNavmenustatus(true);
        this.router.navigate(['/home']);        
      }else{
        this._service.setNavmenustatus(false);
      }     
    })
  }

}
