import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { RailFleetService } from 'src/services/rail-fleet.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  apiResp: any;
  @Input() userAccess;
  @Input() userAdmin;
  isProd: boolean = false;
  shownavmenu: boolean = true;
  menuFlag: boolean = false;
  constructor(private _service: RailFleetService, private router: Router) { }

  ngOnInit() {
    this._service.getNavmenustatus().subscribe((res: boolean) => {
      this.shownavmenu = res;
    });

    const url = document.getElementsByTagName('base')[0].href.toLowerCase();
    if(url.includes('railfleet.basf')) {
      this.isProd = true;
    }
  }

  navigateToLink() {
    console.log("link")
    this._service.getAllDocumentsUrls().subscribe(response => {
      console.log(response);
      window.open(response[0].url, '_blank');
    });
  }

}
