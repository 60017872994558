import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SelectItem } from 'primeng/api';
import { RailFleetService } from 'src/services/rail-fleet.service';

@Component({
  selector: 'app-railcar-urls',
  templateUrl: './railcar-urls.component.html',
  styleUrls: ['./railcar-urls.component.css']
})
export class RailcarUrlsComponent implements OnInit {
  carUrls: SelectItem[];
  apiResp: any;
  dialogHeader = "";
  ddName = "";
  ddDescription = "";
  ddType = "";
  display: boolean = false;
  labelName = "";
  labelDescription = "";
  selectedRailCar: any;
  constructor(private _service: RailFleetService,private _spinner: NgxSpinnerService,private _toastr: ToastrService) { }

  ngOnInit() {
    this.GetAllRailCarUrls();
  }
  GetAllRailCarUrls() {
    this._service.getAllRailCarUrls().subscribe((response:any[])=>{
      this.carUrls=[];
      response.forEach(item=>{
      this.carUrls.push({label:item.descriptionName,value:item.urlID})
      })      
    })
  }
  openPopup(value) {
    this.display = true;
    this.labelName = "Description";
    this.labelDescription = "URL";
    switch (value) {
      case 'rc': this.dialogHeader = "Add RailCar Urls";this.ddType="rc"; break;
      default: this.display = false; break;
    }
  }
  cancelDD() {
    this.display = false;
    this.ddName = "";
    this.ddDescription = "";
  }
  saveDropDown() {
    switch(this.ddType) {
      case 'rc': this.updateRailCarUrls(); break;
      default : this.display = false; break;
    }
  }
  updateRailCarUrls() {
    this._spinner.show();
    const pcPayLoad = {
      urlID: 0,
      descriptionName: this.ddName,
      url: this.ddDescription,
      actionBy: "Prabhu",
      action: "Add"
    }
    this._service.updateRailCarUrls(pcPayLoad).subscribe(response => {
      this.GetAllRailCarUrls();
      this._toastr.success(response.toString(), "Success!");
      this.cancelDD();
      this._spinner.hide();
    })
  }

}
