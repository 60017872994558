import { Component, OnInit } from '@angular/core';
import { Global } from 'src/app/global';

@Component({
  selector: 'app-view-attachments',
  templateUrl: './view-attachments.component.html',
  styleUrls: ['./view-attachments.component.css']
})
export class ViewAttachmentsComponent implements OnInit {
  globalValue: any;
  constructor(private global: Global) {
    this.globalValue = global;
   }

  ngOnInit() {
    window.open(this.globalValue.attachmentsURL, '_blank');
  }

}
