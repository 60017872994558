import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { RailFleetService } from 'src/services/rail-fleet.service';

@Component({
  selector: 'app-invoice-information',
  templateUrl: './invoice-information.component.html',
  styleUrls: ['./invoice-information.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class InvoiceInformationComponent implements OnInit {
  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};
  userId: any;
  constructor(private router: Router, private _service: RailFleetService, private _spinner: NgxSpinnerService,) { }

  ngOnInit() {
    this.userId = sessionStorage.getItem('userId');
    if (this.userId != null && this.userId != 'undefined' && this.userId != '') {
    } else {
      this.router.navigate(['/login']);
    }
    //this.getRailfleetInvoiceData("3")
    //this.getRailfleetInvoiceData("2")
    this.getRailfleetInvoiceData("1")
  }
  onItemSelect(item: any) {
    // console.log(item);
    // console.log(this.selectedItems);
  }
  OnItemDeSelect(item: any) {
    // console.log(item);
    // console.log(this.selectedItems);
  }
  onSelectAll(items: any) {
    // console.log(items);
  }
  onDeSelectAll(items: any) {
    // console.log(items);
  }

  onTabChange(event) {
    // console.log(event);
    this.getRailfleetInvoiceData(event.index + 1)
  }

  invoiceDetails: any

  details(value: any) {
    // console.log(value)
    this.invoiceDetails = value
  }

  nonActioned: any = [{}]
  actioned: any = []
  autoApproved: any = []
  railFleetInvoiceCount:any
  getRailfleetInvoiceData(tabtype) {
    this._spinner.show();
    this._service.getRailfleetInvoiceData(tabtype).subscribe((response:any) => {
      this._spinner.hide();
      this.railFleetInvoiceCount=response.railFleetInvoiceCount
      if (tabtype == '1') {
        this.nonActioned = response.railFleetInvoiceList
      }
      else if (tabtype == '2') {
        this.actioned = response.railFleetInvoiceList
      }
      else if (tabtype == '3') {
        this.autoApproved = response.railFleetInvoiceList
      }
    })

  }

  refresh(value) {
    //console.log(value)
    if (value) {
      //this.getRailfleetInvoiceData("3")
      //this.getRailfleetInvoiceData("2")
      this.getRailfleetInvoiceData("1")
      //console.log(this.nonActioned, this.actioned)
    }
  }

}
